<template>
<section class="app-set app-download">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-12 d-flex justify-content-center">
                    <div class="app-box">
                        <div class="col-md-12">
                            <div class="heading aos" data-aos="fade-up">
                                <h2>Download Our App</h2>
                                <span>Aliquam lorem ante, dapibus in, viverra quis</span>
                            </div>
                        </div>
                        <div class="downlaod-set aos" data-aos="fade-up">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);"><img src="../../../../assets/img/app-gp-02.png" alt="img"></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"><img src="../../../../assets/img/app-ap-02.png" alt="img"></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-12">
                    <div class="appimg-set text-center aos" data-aos="fade-up">
                        <img src="../../../../assets/img/down-app-02.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>