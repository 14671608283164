<template>
<section class="how-work work-five">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading section-five text-center aos" data-aos="fade-up">
                        <span>Explore the greates our services. You won’t be disappointed</span>
                        <h2>How it Works</h2>
                    </div>
                    <div class="howworksec">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="work-steps-box d-flex align-items-center justify-content-center aos" data-aos="fade-up">
                                    <div class="howwork work-steps">
                                        <div class="iconround">
                                            <img src="../../../../assets/img/icon-7.png" alt="">
                                        </div>
                                        <h5>Step 1</h5>
                                        <h3>Choose What To Do</h3>
                                        <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    </div>
                                    <div class="work-line"></div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="work-steps-box d-flex align-items-center justify-content-center aos" data-aos="fade-up">
                                    <div class="howwork work-steps">
                                        <div class="iconround">
                                            <img src="../../../../assets/img/icon-8.png" alt="">
                                        </div>
                                        <h5>Step 2</h5>
                                        <h3>Find What You Want</h3>
                                        <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    </div>
                                    <div class="work-line"></div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="work-steps-box d-flex align-items-center justify-content-center aos" data-aos="fade-up">
                                    <div class="howwork work-steps">
                                        <div class="iconround">
                                            <img src="../../../../assets/img/icon-9.png" alt="">
                                        </div>
                                        <h5>Step 3</h5>
                                        <h3>Amazing Places</h3>
                                        <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    </div>
                                    <div class="work-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>