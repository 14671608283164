<template>
<div class="col-xl-4 col-md-6 pricing-selected">
                            <div class="card">
                                <div class="card-body">
                                    <div class="pricing-header">
                                        <h2>Enterprice</h2>
                                        <p>Monthly Price</p>
                                    </div>
                                    <div class="pricing-card-price">
                                        <h3 class="heading2 price">$1200.00</h3>
                                        <p>Duration: <span>12 Months</span></p>
                                    </div>
                                    <ul class="pricing-options">
                                        <li><i class="far fa-check-circle"></i> One listing submission</li>
                                        <li><i class="far fa-check-circle"></i> 360 days expiration</li>
                                    </ul>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">Subscribed</a>
                                </div>
                            </div>
                        </div>
</template>