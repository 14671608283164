<template>
<div class="card">
            <div class="card-header">
                <div class="card-heads">
                    <h4 class="card-title">Social Widget</h4>
                    <div class="col-auto">
                        <div class="status-toggle">
                            <input id="social" class="check" type="checkbox" checked="">
                            <label for="social" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="setings">
                    <div class="form-group countset">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-12">
                                <div class="socail-links-set">
                                    <ul>
                                        <li class=" dropdown has-arrow main-drop">
                                            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="user-img">
                                                    <i class="fab fa-facebook-f"></i>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>Twitter</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> You Tube</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i> linkedin</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-12">
                                <input type="text" class="form-control" value="https://www.facebook.com">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group countset">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-12">
                                <div class="socail-links-set">
                                    <ul>
                                        <li class=" dropdown has-arrow main-drop">
                                            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="user-img">
                                                    <i class="fab fa-twitter me-2"></i>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> You Tube</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i> linkedin</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-12">
                                <input type="text" class="form-control" value="https://www.twitter.com">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group countset">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-12">
                                <div class="socail-links-set">
                                    <ul>
                                        <li class=" dropdown has-arrow main-drop">
                                            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="user-img">
                                                    <i class="fab fa-youtube me-2"></i>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i> linkedin</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-12">
                                <input type="text" class="form-control" value="https://www.youtube.com">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group countset">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-12">
                                <div class="socail-links-set">
                                    <ul>
                                        <li class=" dropdown has-arrow main-drop">
                                            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="user-img">
                                                    <i class="fab fa-linkedin me-2"></i>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-12">
                                <input type="text" class="form-control" value="https://www.linkedin.com">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group countset">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-12">
                                <div class="socail-links-set">
                                    <ul>
                                        <li class=" dropdown has-arrow main-drop">
                                            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="user-img">
                                                    <i class="fab fa-github me-2"></i>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>
                                                <a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-12">
                                <input type="text" class="form-control" placeholder="Link with http:// Or https://">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <a class="btn  btn-success addsocail"><i class="fa fa-plus me-2"></i>Add New</a>
                </div>
                <div class="form-groupbtn">
                    <a class="btn btn-update">Save</a>
                </div>
            </div>
        </div>
</template>