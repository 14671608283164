<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Popular Services</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="popular" class="check" type="checkbox" checked="">
                                                        <label for="popular" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-head mb-0">Number of service<span>( Min 6 to Max 20 only )</span></label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-groupbtn">
                                                    <a class="btn btn-update me-2">Update</a>
                                                    <a class="btn btn-cancel">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
</template>