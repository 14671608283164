<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Contact Widget</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="widget" class="check" type="checkbox" checked="">
                                                        <label for="widget" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label>Address</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label>Phone</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update">Save</a>
                                            </div>
                                        </div>
                                    </div>
</template>