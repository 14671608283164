<template>

	<div class="main-wrapper">

	<layout-header3></layout-header3>
    
	<!-- Hero Section -->
    <banner></banner>
    <!-- /Hero Section -->
    
    <!-- Category Section -->
    <home3category></home3category>
    <!-- /Category Section -->
    
    <!-- Popular Servides -->
    <home3service></home3service>
    <!-- /Popular Servides -->
    
    <!-- How It Works -->
    <home3work></home3work>
    <!-- /How It Works -->

    <!-- /our app -->
    <home3app></home3app>
    <!-- /our app -->

	<layout-footer3></layout-footer3>
	</div>
</template>
<script>
	export default {
		mounted() {
		},
	}
</script>