<template>
<section class="popular-search">
        <div class="true-search-box aos" data-aos="fade-up">
            <div class="container">
                <div class="true-search-fix">
                    <div class="search-box">
                        <form> 
                            <div class="search-input line">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="What are you looking for?">
                                </div>
                            </div>
                            <div class="search-input">
                                <i class="fas fa-location-arrow bficon"></i>
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="Your Location"> 
                                </div>
                            </div>
                            <router-link to="/search">
                            <div class="search-btn">
                                <button class="btn search_service" >Search</button>
                            </div>
                            </router-link>
                        </form>
                    </div>
                    <div class="search-cat">
                        <i class="fas fa-circle"></i>
                        <span>Popular Searches</span>
                        <router-link to="/search">Electrical  Works</router-link>
                        <router-link to="/search">Cleaning</router-link>
                        <router-link to="/search">AC Repair</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>