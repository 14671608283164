<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <wallettab></wallettab>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="wallet">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Wallet Amt</th>
                                                    <th>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in wallet" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name}}</a>
                                                        </h2>
                                                    </td>
                                                    <td>{{item.phno}}</td>
                                                    <td>{{item.amount}}</td>
                                                    <td>
                                                        <label class="badge badge-success">{{item.button}}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import wallet from '../../../assets/json/admin/wallet/wallet.json'
    import util from '../../../assets/utils/util'
	const images = require.context('../../../assets/admin_img/provider', false, /\.png$|\.jpg$/)
    import Vue from 'vue'
    export default {
        methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
      },
      data() {
      return {
      duration: ["Select Duration", "One Month", "3 Months", "6 Months", "One Year", "2 Years"],
      wallet: wallet
      }
      },
      mounted() {
        util.datatable('#wallet')
      },
    }
  </Script>