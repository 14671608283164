<template>
                        <div class="service-carousel2 popular-sliderservice slider">
                            
                            <div class="service-widget" v-for="item in popularservice" :key="item.id">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" :src="loadImg(item.img)">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <a href="javascript:void(0);">
                                                <img :src="loadImg1(item.img1)" alt="">
                                            </a>
                                            <span class="service-price">{{item.rate}}</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">{{item.name}}</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">{{item.description}}</router-link>
                                    </h3>
                                    <div class="rating mapgridrating1">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                        <span class="d-inline-block average-rating">{{item.rating}}</span>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> <span>{{item.phno}}</span></span>
                                            <span class="col ser-location"><span>{{item.name1}}</span>  <i class="fas fa-map-marker-alt ms-1"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
</template>
<script>
    import popularservice from '../../../../assets/json/website/popularservice.json'
    const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/img/customer', false, /\.png$|\.jpg$/)
	export default {
		mounted() {
        if($('.popular-sliderservice').length > 0) {
        $('.popular-sliderservice').slick({
            dots: true,
            autoplay:false,
            infinite: true,
            prevArrow: false,
            nextArrow: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
		},
        methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
        data() {
        return {
            popularservice: popularservice
        }
    }
	}
</script>