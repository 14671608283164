<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-xl-8 offset-xl-2">
                        
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Add Rating Type</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="card">
                                <div class="card-body">
                                
                                    <!-- Form -->
                                    <form>
                                        <div class="form-group">
                                            <label>Ratings Type</label>
                                            <input class="form-control" type="text">
                                        </div>
                                        <div class="mt-4">
                                            <router-link to="/admin/ratingstype">
                                            <button class="btn btn-primary" type="submit">Submit</button>
                                            </router-link>
                                            <router-link to="/admin/ratingstype" class="btn btn-link">Cancel</router-link>
                                        </div>
                                    </form>
                                    <!-- /Form -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>