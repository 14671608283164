<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <subcategoryfilter></subcategoryfilter>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="subcategories">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Sub Category</th>
                                                    <th>Category</th>
                                                    <th>Date</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in subcategories" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>
                                                        <img class="rounded service-img me-1" :src="loadImg(item.img)" alt=""> {{item.name}}
                                                    </td>
                                                    <td>{{item.name1}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td class="text-end">
                                                        <router-link to="/admin/edit-subcategory" class="btn btn-sm bg-success-light me-2">
                                                            <i class="far fa-edit me-1"></i>{{item.button}}
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import Vue from 'vue'
    import subcategories from '../../../assets/json/admin/subcategories.json'
    import util from '../../../assets/utils/util'
    const images = require.context('../../../assets/admin_img/sub-category', false, /\.png$|\.jpg$/)
    export default {
      mounted() {
        util.datatable('#subcategories')
      },
      methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            subcategories: subcategories
        }
    }
    }
  </Script>