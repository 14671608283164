<template>
<!-- Category Section -->
		<section class="category-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="row">
							<div class="col-md-6">
								<div class="heading aos" data-aos="fade-up">
									<h2>Featured Categories</h2>
									<span>What do you need to find?</span>
								</div>
							</div>
							<div class="col-md-6">
								<div class="viewall aos" data-aos="fade-up">
									<h4><router-link to="/categories">View All <i class="fas fa-angle-right"></i></router-link></h4>
									<span>Featured Categories</span>
								</div>
							</div>
						</div>
						<div class="catsec">
							<div class="row"> 
								<div class="col-lg-4 col-md-6" v-for="item in users_specialization" :key="item.id"  @click="openSubCat(item)" >
								<!-- <div class="col-lg-4 col-md-6" v-for="item in users_specialization" :key="item.id"> -->
									<!-- <router-link active-class="active" :to='"Subcats/"+item.id'> -->
										<!-- <router-link active-class="active" to="/categories" > -->
										<div class="cate-widget aos" data-aos="fade-up">
											<img :src="$store.state.apiImage+item.image" alt="" >
											<div class="cate-title">
												<h3><span ><i class="fas fa-circle"></i> {{item.specialization}}</span></h3>
											</div>
											<div class="cate-count">
												<!-- <i class="fas fa-clone"></i> {{item.id}} -->
											</div>
										</div>
									<!-- </router-link> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Category Section -->
</template>
<script>
import axios from 'axios';
	import category from '../../../../assets/json/website/home/category.json'
	const images = require.context('../../../../assets/img/category', false, /\.png$|\.jpg$/)
	export default {
	methods:{
		openSubCat(item){
			this.$router.push({
    name: 'SubcatsPg', 
    params: { catid: item.id }
});

			// this.$router.push("/Subcats/"+item.id); 
			// window.location.href = "/Subcats/"+item.id;
			// setTimeout(() => {
			// this.$router.go("/Subcats/"+item.id);
			// }, 200);
			
		},
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
		getSpecializationMasterList() {
		axios
			.get(this.$store.state.apiEndpoint + "api/SpecializationMasterList")
			.then(response => {
			this.users_specialization = response.data;
			console.log(response);
		})
			.catch(error => {
			console.log(error);
			alert("auth error");
		});
	},
    },
	mounted() {
	this.getSpecializationMasterList();
	},
	data() {
        return {
			users_specialization: [],
            category: category
        }
    }
	}
	</script>

