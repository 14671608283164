<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <detailcontent></detailcontent>
                        <detailcontent1></detailcontent1>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	export default {
	mounted() {
        if($('.testimonials-slider').length > 0) {
        $('.testimonials-slider').slick({
            dots: true,
            autoplay:false,
            infinite: true,
            prevArrow: false,
            nextArrow: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }

	},
	}
	</script>