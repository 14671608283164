<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row align-items-center mb-4">
                        <div class="col">
                            <h4 class="widget-title mb-0">Booking List</h4>
                        </div>
                        <div class="col-auto">
                            <div class="sort-by">
                                <select class="form-control-sm custom-select searchFilter" id="status">
                                    <option>All</option>
                                    <option>Pending</option>
                                    <option>Inprogress</option>
                                    <option>Complete Request</option>
                                    <option>Rejected</option>
                                    <option>Cancelled</option>
                                    <option>Completed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="dataList">
                        
                        <providerbooking1></providerbooking1>
                        
                        <div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="../../../../assets/img/services/service-05.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">House Cleaning Services</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 23 Jul 2020 <span class="badge badge-pill badge-prof bg-danger">Rejected by User</span>
                                            </li>
                                            <li><span>Booking time</span> 12:00:00 - 13:00:00</li>
                                            <li><span>Amount</span> $500</li>
                                            <li><span>Location</span> 4371 Maloy Court Rush Center</li>
                                            <li><span>Phone</span> 410-242-3850</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="../../../../assets/img/customer/user-04.jpg">
                                                </div>
                                                Ricardo Lung
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <button type="button" data-id="542" class="btn btn-sm bg-default-light"><i class="fas fa-info-circle"></i> Reason</button>
                                </div>
                            </div>
                        </div>
                        <div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="../../../../assets/img/services/service-06.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">Computer & Server AMC Service</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 22 Jul 2020 <span class="badge badge-pill badge-prof bg-warning">Pending</span>
                                            </li>
                                            <li><span>Booking time</span> 12:00:00 - 13:00:00</li>
                                            <li><span>Amount</span> $4</li>
                                            <li><span>Location</span> 1346 Simpson Street Davenport</li>
                                            <li><span>Phone</span> 410-242-3850</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="../../../../assets/img/customer/user-05.jpg">
                                                </div>
                                                Annette Silva
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <a href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> User Request Accept</a>
                                    <a href="javascript:;" class="btn btn-sm bg-danger-light" data-toggle="modal" data-target="#myCancel"><i class="fas fa-times"></i> Cancel the Service</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="../../../../assets/img/services/service-07.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">Interior Designing</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 22 Jul 2020 <span class="badge badge-pill badge-prof bg-warning">Pending</span>
                                            </li>
                                            <li><span>Booking time</span> 11:00:00 - 12:00:00</li>
                                            <li><span>Amount</span> $100</li>
                                            <li><span>Location</span> 171 Jadewood Farms Newark</li>
                                            <li><span>Phone</span> 412-355-7471</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="../../../../assets/img/customer/user-06.jpg">
                                                </div>
                                                Stephen Wilson
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <a href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> User Request Accept</a>
                                    <a href="javascript:;" class="btn btn-sm bg-danger-light" data-toggle="modal" data-target="#myCancel"><i class="fas fa-times"></i> Cancel the Service</a>
                                </div>
                            </div>
                        </div> 
                        <div class="pagination">
                            <ul>
                                <li class="active">
                                    <a href="javascript:void(0);">1</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">2</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">3</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">4</a>
                                </li>
                                <li class="arrow">
                                    <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
        </div>
	
	<layout-footer></layout-footer>

	</div>

</template>