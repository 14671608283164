<template>
<div class="card">
                        <div class="card-body">
                            <div class="plan-det">
                                <h6 class="title">Plan Details</h6>
                                <ul class="row">
                                    <li class="col-sm-4">
                                        <p><span class="text-muted">Started On</span> 15 Jul 2020</p>
                                    </li>
                                    <li class="col-sm-4">
                                        <p><span class="text-muted">Price</span> $1502.00</p>
                                    </li>
                                    <li class="col-sm-4">
                                        <p><span class="text-muted">Expired On</span> 15 Jul 2021</p>
                                    </li>
                                </ul>
                                <h6 class="title">Last Payment</h6>
                                <ul class="row">
                                    <li class="col-sm-4">
                                        <p>Paid at 15 Jul 2020</p>
                                    </li>
                                    <li class="col-sm-4">
                                        <p><span class="amount">$1502.00 </span>  <span class="badge bg-success-light">Paid</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
</template>