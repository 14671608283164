<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="page-title">Frontend Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="row">
                                <div class=" col-lg-6 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Categories Widget</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="categories" class="check" type="checkbox" checked="">
                                                        <label for="categories" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-head mb-0">Categories<span>( Max 6 only )</span></label>
                                            </div>
                                            <div class="settings-form">
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Appliance">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/appliance">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Carpentry">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/carpentry">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Plumbing">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/plumbing">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Construction">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/construction">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" placeholder="Label">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" placeholder="Link with http:// Or https://">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <a class="btn  btn-success addlinks"><i class="fa fa-plus me-2"></i>Add New</a>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update">Save</a>
                                            </div>
                                        </div>
                                    </div>
                                    <leftfooter1></leftfooter1>
                                    <leftfooter2></leftfooter2>
                                </div>
                                <rightfooter></rightfooter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      data() {
      return {
        password: ["Select Mail Encryption", "Mail Encryption", "Encryption"]
      }
      },
      components: {
     
      },
      mounted() {
    $(document).on("click",".addlinks",function () {
	var experiencecontent = '<div class="form-group links-cont">' +
	'<div class="row align-items-center">' +
	'<div class="col-lg-3 col-12">' +
	'<input type="text" class="form-control" placeholder="Label">' +
	'</div>' +
	'<div class="col-lg-8 col-12">' +
	'<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
	'</div>' +
	'<div class="col-lg-1 col-12">' +
	'<a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">' +
	'<i class="far fa-trash-alt "></i> ' +
	'</a>' +
	'</div>' +
	'</div>' +
	'</div>' ;
	
	$(".settings-form").append(experiencecontent);
	return false;
    });
    $(".settings-form").on('click','.delete_review_comment', function () {
	$(this).closest('.links-cont').remove();
	return false;
     });
     $(document).on("click",".addnew",function () {
	var experiencecontent = '<div class="form-group links-conts">' +
	'<div class="row align-items-center">' +
	'<div class="col-lg-3 col-12">' +
	'<input type="text" class="form-control" placeholder="Label">' +
	'</div>' +
	'<div class="col-lg-8 col-12">' +
	'<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
	'</div>' +
	'<div class="col-lg-1 col-12">' +
	'<a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">' +
	'<i class="far fa-trash-alt "></i> ' +
	'</a>' +
	'</div>' +
	'</div>' +
	'</div>' ;
	
	$(".settingset").append(experiencecontent);
	return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
	$(this).closest('.links-conts').remove();
	return false;
});
      },
    }
  </Script>