<template>
  	<div class="main-wrapper">

<!-- <layout-headercustomer></layout-headercustomer> -->
  <layout-header></layout-header>
  
  <div class="content">
      <div class="container">
<div>
  <div class="vx-row">
 <div class="w-full">
          <!-- <vx-card  title="Make Payment"> -->
          <table :data="users_requestpaylist">
            <thead>
            <th>issue title</th>
            <th>amount</th>
            <th>currency</th>
            <th>status</th>
            <!-- <vs-th>payment_reference_id</vs-th>
            <vs-th>payment_mode</vs-th>
            <vs-th>Action</vs-th> -->

            </thead>

        <tbody>
            <tr :key="indextr" v-for="(tr, indextr) in data">

            <td :data="data[indextr].amount">   {{ data[indextr].enquiry.issue_title }} </td>
            <td :data="data[indextr].amount">   {{ data[indextr].amount }}              </td>
            <td :data="data[indextr].currency"> {{ data[indextr].currency }}            </td>
            <td :data="data[indextr].status">   {{ status[data[indextr].status] }}      </td>
            <!-- <vs-td :data="data[indextr].payment_reference_id">        {{ data[indextr].payment_reference_id }}         </vs-td>
            <vs-td :data="data[indextr].payment_mode">        {{ data[indextr].payment_mode }}         </vs-td>
            
            <vs-td><vs-button   type="border" icon-pack="feather" icon="icon-arrow-right-circle" color="primary" @click="goToReg1(tr)" />
            </vs-td> -->
            

            </tr>
        </tbody>
        </table>
                        <!-- </vx-card> -->
  </div>
 <!-- to be chcked and removed -->
 
                    </div>

                    <h2>payments</h2>

                    <!-- <stripe-element-payment
                    v-if="canLoad"
      ref="paymentRef"
      pk="pk_live_51KueO2Ihs1sG6BYS2NaxmNLDAlnYzvguHJ5edf1yhSZDx6iQKJvAsQs9G40yxfe0zwPcZw0Vuw1jAao7VCz0Sntu00Fckv2IW7"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
    /> -->

    {{elementsOptions}}
    {{confirmParams}}
    <button v-if="canLoad" @click="pay">Pay Now</button>
    {{confirmParams.return_url}}
    <p>refid: {{ref_id}}</p>
</div>

</div>
    </div>
	
	<layout-footer></layout-footer>

	</div>
</template>


<script>
import axios from "axios";
// import { StripeElementPayment } from '@vue-stripe/vue-stripe';
export default {
  components: {
    // StripeElementPayment
  },
  data() {
    return {
      isSaved: false,
      canLoad: false,
      createdata: {},
      ref_id: "",
      users_requestpaylist:[],
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: '', // success url
      },
      status:[
          "New",
          "Requested",
          "Payment initiated",
          "Payment processing",
          "Payment success",
          "Payment failed",
          "Payment re-initiated"

      ],
    };

  },

  methods: {
	  
    getData: function() {},
    createDate: function() {
      this.$vs.notify({
        title: "Successfully Submitted",
        text: "Submitted for Estimation and Engineering Successfully",
        color: "success",
        iconPack: "feather",
        icon: "icon-check"
      });
    },
     getListPayment(){
      const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/getpaymentbyid/"+ this.$route.params.id,auth)
                .then(response => {
                  this.users_requestpaylist=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });
        },
        async generatePaymentIntent () {
          
      const auth =  {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
          axios
                .get(this.$store.state.apiEndpoint +"api/makepayment/"+this.$route.params.id, auth)
                .then(response => {
                  this.canLoad = true;
                  const paymentIntent = response.data //await apiCallToGeneratePaymentIntent(); // this is just a dummy, create your own API call
                  this.elementsOptions.clientSecret = paymentIntent.client_secret;
                  this.ref_id = paymentIntent.id;
                  // this.users_requestpaylist=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });

      
    },
    pay () {
       this.users_requestpaylist[0].payment_reference_id = this.ref_id;
       this.users_requestpaylist[0].status = 2; // payment initiated;
      axios
                .put(this.$store.state.apiEndpoint +"api/enquiryPaymentRequestUpdate", this.users_requestpaylist[0])
                .then(response => {
                   this.$refs.paymentRef.submit();
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });
     
    },
  },
  mounted() {
    this.confirmParams.return_url = this.$store.state.payment_success;
    setTimeout(() => {
    this.generatePaymentIntent();
      
    }, 1000);
    this.getListPayment();
  }
};
</script>
