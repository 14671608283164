<template>
<div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Withdraw</h4>
                                    <form action="#">
                                        <div class="form-group">
                                            <div class="input-group mb-3">
                                                <label class="input-group-text display-5">$</label>
                                                <input type="text" maxlength="4" class="form-control Withdraw-form" placeholder="00.00">
                                            </div>
                                        </div>
                                    </form>
                                    <div class="text-center mb-3">
                                        <h5 class="mb-3">OR</h5> 
                                        <ul class="list-inline mb-0">
                                            <li class="line-inline-item mb-0 d-inline-block me-1">
                                                <a href="javascript:;" class="updatebtn">$50</a>
                                            </li>
                                            <li class="line-inline-item mb-0 d-inline-block">
                                                <a href="javascript:;" class="updatebtn">$100</a>
                                            </li>
                                            <li class="line-inline-item mb-0 d-inline-block ms-1">
                                                <a href="javascript:;" class="updatebtn">$150</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-block withdraw-btn w-100">Withdraw</a>
                                </div>
                            </div>
                        </div>
</template>