<template>
<div class="links-info">
                                                <div class="row form-row links-cont">
                                                    <div class="form-group form-placeholder d-flex">
                                                        <button class="btn social-icon">
                                                            <i class="feather-facebook"></i>
                                                        </button>
                                                        <input type="text" class="form-control" placeholder="https://www.facebook.com">
                                                        <div>
                                                            <a href="javascript:void(0);" class="btn trash">
                                                                <i class="feather-trash-2"></i>
                                                            </a>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div class="links-info">
                                                <div class="row form-row links-cont">
                                                    <div class="form-group form-placeholder d-flex">
                                                        <button class="btn social-icon">
                                                            <i class="feather-twitter"></i>
                                                        </button>
                                                        <input type="text" class="form-control" placeholder="https://www.twitter.com">
                                                        <div>
                                                            <a href="javascript:void(0);" class="btn trash">
                                                                <i class="feather-trash-2"></i>
                                                            </a>
                                                        </div>
                                                    </div> 
                                                    
                                                </div>
                                            </div>
</template>