<template>
<div class="col-md-6 d-flex">
                        
                            <!-- Payments -->
                            <div class="card card-table flex-fill">
                                <div class="card-header">
                                    <h4 class="card-title">Payments</h4>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-center">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Provider</th>
                                                    <th>Service</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in list1" :key="item.id">
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-xs me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.user}}</a>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-xs me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg1(item.img1)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.provider}}</a>
                                                        </span>
                                                    </td>
                                                    <td>{{item.service}}</td>
                                                    <td>{{item.amount}}</td>
                                                    <td>
                                                        <span class="badge badge-dark">{{item.Status}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Payments -->
                            
                        </div>
</template>
<script>
	import list1 from '../../../assets/json/admin/index/list1.json'
    import util from '../../../assets/utils/util'
	const images = require.context('../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../assets/admin_img/provider', false, /\.png$|\.jpg$/)
	export default {
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            list1: list1
        }
    }
	}
	</script>
