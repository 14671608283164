<template>

   <div class="body-two">

	<div class="main-wrapper">

	<layout-header2></layout-header2>
    
	<!-- Hero Section -->
    <section class="hero-section hero-two">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 ">
                    <div class="section-search-box d-flex align-items-center aos" data-aos="fade-up" >
                        <div class="section-search">
                            <h3>World's Largest <span>Marketplace</span></h3>
                            <p>Search From 150 Awesome Verified Ads!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Hero Section -->
    
    <!-- home Search -->
    <home2search></home2search>
    <!-- /home Search -->
    
    <!-- Featured Category  -->
    <home2category></home2category>
    <!-- Featured Category -->
    
    <!-- Popular Servides -->
    <home2service></home2service>
    <!-- /Popular Servides -->
    
    <!-- How It Works -->
    <home2work></home2work>
    <!-- /How It Works -->

    <!-- /our app -->
    <home2app></home2app>
    <!-- /our app -->

	<layout-footer2></layout-footer2>

	</div>

   </div>

</template>
<script>
	export default {
		mounted() {
		},
	}
</script>