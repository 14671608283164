<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-xl-8 offset-xl-2">
                        
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Add Subscription</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="card">
                                <div class="card-body">
                                
                                    <!-- Form -->
                                    <form>
                                        <div class="form-group">
                                            <label>Subscription Name</label>
                                            <input class="form-control" type="text" placeholder="Free Trial">
                                        </div>
                                        <div class="form-group">
                                            <label>Subscription Amount</label>
                                            <input class="form-control" type="text">
                                        </div>
                                        <div class="form-group">
                                            <label>Subscription Durations</label>
                                            <vue-select :options="duration" />
                                        </div>
                                        <div class="form-group">
                                            <label class="d-block">Subscription Status</label>
                                            <div class="form-check form-check-inline form-radio">
                                                <input class="form-check-input" type="radio" value="" id="subscription_active" checked>
                                                <label class="form-check-label" for="subscription_active">
                                                    Active
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline form-radio">
                                                <input class="form-check-input" type="radio" value="" id="subscription_inactive" >
                                                <label class="form-check-label" for="subscription_inactive">
                                                    Inactive
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mt-0">
                                            <router-link to="/admin/subscriptions">
                                            <button class="btn btn-primary" type="submit">Submit</button>
                                            </router-link>
                                            <router-link to="/admin/subscriptions" class="btn btn-link">Cancel</router-link>
                                        </div>
                                    </form>
                                    <!-- /Form -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import Vue from 'vue'
    export default {
      data() {
      return {
      duration: ["Select Duration", "One Month", "3 Months", "6 Months", "One Year", "2 Years"]
      }
      },
      components: {
     
      },
      mounted() {
      },
    }
  </Script>