<template>
<div class="col-lg-5 col-md-6">
                                            <div class="invoice-total-card">
                                                <h4 class="invoice-total-title">Summary</h4>
                                                <div class="invoice-total-box">
                                                    <div class="invoice-total-inner">
                                                        <p>Taxable Amount <span>$21</span></p>
                                                        <p>Round Off 
                                                            <input type="checkbox" id="status_1" class="check">
                                                            <label for="status_1" class="checktoggle">checkbox</label> 
                                                            <span>$54</span>
                                                        </p>
                                                        <div class="links-info-one">
                                                            <div class="links-info">
                                                                <div class="links-cont">
                                                                    <a href="javascript:void(0);" class="service-trash">
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:void(0);" class="add-links add-links-bg">
                                                            <i class="fas fa-plus-circle me-1"></i> Additional Charges
                                                        </a>
                                                        <div class="links-info-discount">
                                                            <div class="links-cont-discount">
                                                                <a href="javascript:void(0);" class="add-links-one">
                                                                    <i class="fas fa-plus-circle me-1"></i> Add more Discount
                                                                </a>
                                                                <a href="javascript:void(0);" class="service-trash-one">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="invoice-total-footer">
                                                        <h4>Total Amount <span>$ 894.00</span></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="upload-sign">
                                                <div class="form-group service-upload">
                                                    <span>Upload Sign</span>
                                                    <input type="file" multiple>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Name of the Signatuaory">
                                                </div>
                                                <div class="form-group float-end mb-0">
                                                    <button class="btn btn-primary" type="submit">Save Invoice</button>
                                                </div>
                                            </div>
                                        </div>
</template>