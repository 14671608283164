<template>
    <div class=" col-lg-6 col-sm-12 col-12">
        <div class="card">
            <div class="card-header">
                <div class="card-heads">
                    <h4 class="card-title">Links Widget</h4>
                    <div class="col-auto">
                        <div class="status-toggle">
                            <input id="links" class="check" type="checkbox" checked="">
                            <label for="links" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" placeholder=""/>
                </div>
                <div class="form-group">
                    <label class="form-head mb-0">Categories<span>( Max 6 only )</span></label>
                </div>
                <div class="settings-forms">
                    <div class="form-group links-cont">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-12">
                                <input type="text" class="form-control" value="About us">
                            </div>
                            <div class="col-lg-8 col-12">
                                <input type="text" class="form-control" value="https://truelysell.com/about-us">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group links-cont">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-12">
                                <input type="text" class="form-control" value="Contact Us">
                            </div>
                            <div class="col-lg-8 col-12">
                                <input type="text" class="form-control" value="https://truelysell.com/contact-us">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group links-cont">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-12">
                                <input type="text" class="form-control" value="Help">
                            </div>
                            <div class="col-lg-8 col-12">
                                <input type="text" class="form-control" value="https://truelysell.com/help">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group links-cont">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-12">
                                <input type="text" class="form-control" value="FAQ">
                            </div>
                            <div class="col-lg-8 col-12">
                                <input type="text" class="form-control" value="https://truelysell.com/faq">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group links-cont">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-12">
                                <input type="text" class="form-control" placeholder="Label">
                            </div>
                            <div class="col-lg-8 col-12">
                                <input type="text" class="form-control" placeholder="Link with http:// Or https://">
                            </div>
                            <div class="col-lg-1 col-12">
                                <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                    <i class="far fa-trash-alt "></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <a class="btn  btn-success addlinknew"><i class="fa fa-plus me-2"></i>Add New</a>
                </div>
                <div class="form-groupbtn">
                    <a class="btn btn-update">Save</a>
                </div>
            </div>
        </div>
        <rightfooter1></rightfooter1>
    </div>
</template>
<script>
    import Vue from 'vue'
    export default {
      mounted() {
        $(document).on("click",".addlinknew",function () {
	var experiencecontent = '<div class="form-group links-cont">' +
	'<div class="row align-items-center">' +
	'<div class="col-lg-3 col-12">' +
	'<input type="text" class="form-control" placeholder="Label">' +
	'</div>' +
	'<div class="col-lg-8 col-12">' +
	'<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
	'</div>' +
	'<div class="col-lg-1 col-12">' +
	'<a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">' +
	'<i class="far fa-trash-alt "></i> ' +
	'</a>' +
	'</div>' +
	'</div>' +
	'</div>' ;
	
	$(".settings-forms").append(experiencecontent);
	return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
	$(this).closest('.links-cont').remove();
	return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
	var experiencecontent = '<div class="form-group countset">' +
	'<div class="row align-items-center">' +
	'<div class="col-lg-2 col-12">' +
	'<div class="socail-links-set">' +
	'<ul>' +
	'<li class=" dropdown has-arrow main-drop">' +
	'<a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
	'<span class="user-img">' +
	'<i class="fab fa-github me-2"></i>' +
	'</span>' +
	'</a>' +
	'<div class="dropdown-menu">' +
	'<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
	'<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
	'<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
	'</div>' +
	'</li>' +
	'</ul>' +
	'</div>' +
	'</div>' +
	'<div class="col-lg-9 col-12">' +
	'<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
	'</div>' +
	'<div class="col-lg-1 col-12">' +
	'<a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">' +
	'<i class="far fa-trash-alt "></i> ' +
	'</a>' +
	'</div>' +
	'</div> ' +
	'</div> ';
	
	$(".setings").append(experiencecontent);
	return false;
});

$(".setings").on('click','.delete_review_comment', function () {
	$(this).closest('.countset').remove();
	return false;
});
      },
    }
  </Script>