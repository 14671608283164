<template>

<!-- Footer -->
<footer class="footer">
		
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">Quick Links  </h2>
                        <ul>
                            <li>
                                <!-- <router-link to="/about-us">About Us</router-link> -->
                                <router-link to="#">About Us</router-link>
                            </li>
                            <li>
                                <router-link to="#">Contact Us</router-link>
                                <!-- <router-link to="/contact-us">Contact Us</router-link> -->
                            </li>
                            <li>
                                <router-link to="#">Faq</router-link>
                                <!-- <router-link to="/faq">Faq</router-link> -->
                            </li>
                            <li>
                                <a href="javascript:void(0);">Help</a>
                            </li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">Upcoming Categories</h2>
                        <ul>
                            <li>
                                <router-link to="#">Plumbing</router-link>
                                <!-- <router-link to="/search">Computer</router-link> -->
                            </li>
                            <li>
                                <router-link to="#">Electricain</router-link>
                            </li>
                            <li>
                                <router-link to="#">Basement Upgrade</router-link>
                            </li>
                            <li>
                                <router-link to="#">Cleaning</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-contact">
                        <h2 class="footer-title">Contact Us</h2>
                        <div class="footer-contact-info">
                            <div class="footer-address">
                                <span><i class="far fa-building"></i></span>
                                <p>Canada</p>
                            </div>
                            <p><i class="fas fa-headphones"></i>+1 587 989 8216</p>
                            <p class="mb-0"><i class="fas fa-envelope"></i> info@skilledone.ca</p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <!-- <div class="footer-widget">
                        <h2 class="footer-title">Follow Us</h2>
                        <div class="social-icon">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook-f"></i> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-google"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Enter your email">
                            <button type="submit" class="btn footer-btn">
                                <i class="fas fa-paper-plane"></i>
                            </button>
                        </div>
                    </div> -->
                    <!-- /Footer Widget -->
                </div>
            </div>
        </div>
    </div>
    <!-- /Footer Top -->
    
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <!-- Copyright -->
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 col-lg-6">
                        <div class="copyright-text">
                            <p class="mb-0">&copy; 2022 <router-link to="/">Skilledone</router-link>. All rights reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <!-- Copyright Menu -->
                        <div class="copyright-menu">
                            <ul class="policy-menu">
                                <li>
                                    <router-link to="/" class="me-2">Terms and Conditions</router-link>
                                    <!-- <router-link to="/term-condition" class="me-2">Terms and Conditions</router-link> -->
                                </li>
                                <li>
                                    <router-link to="/">Privacy</router-link>
                                    <!-- <router-link to="/privacy-policy1">Privacy</router-link> -->
                                </li>
                            </ul>
                        </div>
                        <!-- /Copyright Menu -->
                    </div>
                </div>
            </div>
            <!-- /Copyright -->
        </div>
    </div>
    <!-- /Footer Bottom -->
    
</footer>
<!-- /Footer -->

</template>