<template>
<!-- How It Works -->
                <div class="howworksec">
                    <div class="row">
                        <div class="col-lg-4" v-for="item in work" :key="item.id">
                            <div class="howwork aos" data-aos="fade-up">
                                <div class="iconround">
                                    <div class="steps">{{item.no}}</div>
                                    <img :src="loadImg(item.img)" alt="">
                                </div>
                                <h3>{{item.name}}</h3>
                                <p>{{item.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
<!-- /How It Works -->
</template>
<script>
	import work from '../../../../assets/json/website/home/work.json'
	const images = require.context('../../../../assets/img', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            work: work
        }
    }
	}
	</script>