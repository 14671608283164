<template>
    <div class="col-xl-3 col-md-4 theiaStickySidebar">
        <div class="stickyside">
        <div class="mb-4">
            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                <img alt="profile image" :src="user_pic" class="avatar-lg rounded-circle">
                <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                    <h6 class="mb-0">{{user_name}}</h6>
                    <p class="text-muted mb-0">{{user_email}}</p>
                </div>
            </div>
        </div>
        <div class="widget settings-menu">
            <ul role="tablist" class="nav nav-tabs">
                <!-- <li class="nav-item current">
                    <router-link to="/user-dashboard" class="nav-link">
                        <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                    </router-link>
                </li>
                <li class="nav-item current">
                    <router-link to="/favourites" class="nav-link">
                        <i class="fas fa-heart"></i> <span>Favourites</span>
                    </router-link>
                </li> -->
                <li class="nav-item current">
                    <router-link to="/user-bookings" class="nav-link">
                        <i class="far fa-calendar-check"></i> <span>My Bookings</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/user-settings" class="nav-link">
                        <i class="far fa-user"></i> <span>Profile Settings</span>
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link to="/user-wallet" class="nav-link">
                        <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link to="/user-reviews" class="nav-link">
                        <i class="far fa-star"></i> <span>Reviews</span>
                    </router-link>
                </li> -->
                <li class="nav-item">
                    <router-link to="/user-payment" class="nav-link">
                        <i class="fas fa-hashtag"></i> <span>Payment</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  data() {
  return {
    user_pic_id:"",
    user_name:"",
    user_email:"",
    user_pic:"",
  }
},
mounted() {
    // console.log(localStorage)
    this.user_pic_id=localStorage.user_id;
    this.user_name=localStorage.user_name;
    this.user_email=localStorage.user_email;
    this.user_pic=localStorage.user_userprofileimage?this.$store.state.apiImage+localStorage.user_userprofileimage:'';
  } 
}
</script>