<template>
<!-- Header -->
<div class="header">
    <div class="header-left"> 
        <router-link to="/admin/index" class="logo logo-small">
            <img src="../../assets/admin_img/logo-icon.png" alt="Logo" width="30" height="30">
        </router-link>
    </div>
    <a href="javascript:void(0);" id="toggle_btn">
        <i class="fas fa-align-left"></i>
    </a>
    <a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
        <i class="fas fa-align-left"></i>
    </a>
    
    <ul class="nav user-menu">

        <!-- Notifications -->
        <li class="nav-item dropdown noti-dropdown">
            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <i class="far fa-bell"></i>  <span class="badge badge-pill"></span>
            </a>
            <div class="dropdown-menu notifications">
                <div class="topnav-dropdown-header">
                    <span class="notification-title">Notifications</span>
                    <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                </div>
                <div class="noti-content">
                    <ul class="notification-list">
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-01.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Thomas Herzberg have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">15 Sep 2020 10:20 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-02.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Matthew Garcia have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">13 Sep 2020 03:56 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-03.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Yolanda Potter have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">12 Sep 2020 09:25 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-04.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Ricardo Flemings have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">11 Sep 2020 06:36 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-05.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Maritza Wasson have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">10 Sep 2020 08:42 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-06.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Marya Ruiz have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">9 Sep 2020 11:01 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-07.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Richard Hughes have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">8 Sep 2020 06:23 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="topnav-dropdown-footer">
                    <router-link to="/admin/admin-notification">View all Notifications</router-link>
                </div>
            </div>
        </li>
        <!-- /Notifications -->

        <!-- Chat -->
        <li class="nav-item dropdown noti-dropdown">
            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <i class="far fa-comments"></i>
            </a>
            <div class="dropdown-menu notifications">
                <div class="topnav-dropdown-header">
                    <span class="notification-title">Notifications</span>
                    <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                </div>
                <div class="noti-content">
                    <ul class="notification-list">
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-02.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Matthew Garcia have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">13 Sep 2020 03:56 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-01.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Thomas Herzberg have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">15 Sep 2020 10:20 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="" src="../../assets/admin_img/provider/provider-03.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Yolanda Potter have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">12 Sep 2020 09:25 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-04.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Ricardo Flemings have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">11 Sep 2020 06:36 PM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-05.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Maritza Wasson have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">10 Sep 2020 08:42 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-06.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Marya Ruiz have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">9 Sep 2020 11:01 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="notification-message">
                            <router-link to="/admin/admin-notification">
                                <div class="media d-flex">
                                    <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/admin_img/provider/provider-07.jpg">
                                    </span>
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Richard Hughes have been subscribed</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">8 Sep 2020 06:23 AM</span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="topnav-dropdown-footer">
                    <router-link to="/admin/admin-notification">View all Notifications</router-link>
                </div>
            </div>
        </li>
        <!-- /Chat -->
        
        <!-- User Menu -->
        <li class="nav-item dropdown">
            <a href="javascript:void(0)" class="dropdown-toggle user-link  nav-link" data-bs-toggle="dropdown">
                <span class="user-img">
                    <img class="rounded-circle" src="../../assets/admin_img/user.jpg" width="40" alt="Admin">
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <router-link class="dropdown-item" to="/admin/admin-profile">Profile</router-link>
                <router-link class="dropdown-item" to="/admin/login">Logout</router-link>
            </div>
        </li>
        <!-- /User Menu -->
    </ul>
</div>
<!-- /Header -->		
</template>
<script>
    export default {
        mounted() {
            // Variables declarations
	
	var $wrapper = $('.main-wrapper');
	var $pageWrapper = $('.page-wrapper');
	var $slimScrolls = $('.slimscroll');
	
	// Sidebar
	
	var Sidemenu = function() {
		this.$menuItem = $('#sidebar-menu a');
	};
	
	function init() {
		var $this = Sidemenu;
		$('#sidebar-menu a').on('click', function(e) {
			if($(this).parent().hasClass('submenu')) {
				e.preventDefault();
			}
			if(!$(this).hasClass('subdrop')) {
				$('ul', $(this).parents('ul:first')).slideUp(350);
				$('a', $(this).parents('ul:first')).removeClass('subdrop');
				$(this).next('ul').slideDown(350);
				$(this).addClass('subdrop');
			} else if($(this).hasClass('subdrop')) {
				$(this).removeClass('subdrop');
				$(this).next('ul').slideUp(350);
			}
		});
		$('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
	}
	
	// Sidebar Initiate
	init();
	
	// Mobile menu sidebar overlay
	
	$('body').append('<div class="sidebar-overlay"></div>');
	$(document).on('click', '#mobile_btn', function() {
		$wrapper.toggleClass('slide-nav');
		$('.sidebar-overlay').toggleClass('opened');
		$('html').addClass('menu-opened');
		return false;
	});
	
	// Sidebar overlay
	
	$(".sidebar-overlay").on("click", function () {
		$wrapper.removeClass('slide-nav');
		$(".sidebar-overlay").removeClass("opened");
		$('html').removeClass('menu-opened');
	});	
    // Small Sidebar

	$(document).on('click', '#toggle_btn', function() {
		if($('body').hasClass('mini-sidebar')) {
			$('body').removeClass('mini-sidebar');
			$('.subdrop + ul').slideDown();
		} else {
			$('body').addClass('mini-sidebar');
			$('.subdrop + ul').slideUp();
		}
		setTimeout(function(){ 
			mA.redraw();
			mL.redraw();
		}, 300);
		return false;
	});
	
	$(document).on('mouseover', function(e) {
		e.stopPropagation();
		if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
			var targ = $(e.target).closest('.sidebar').length;
			if(targ) {
				$('body').addClass('expand-menu');
				$('.subdrop + ul').slideDown();
			} else {
				$('body').removeClass('expand-menu');
				$('.subdrop + ul').slideUp();
			}
			return false;
		}
		
		$(window).scroll(function() {
			if ($(window).scrollTop() >= 30) {
				$('.header').addClass('fixed-header');
			} else {
				$('.header').removeClass('fixed-header');
			}
		});
		
		$(document).on('click', '#loginSubmit', function() {
			$("#adminSignIn").submit();
		});
		
	});
    },
       computed: {
           currentPath() {
               return this.$route.name
           },
       }
    }
    </script>