// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import { router } from './router';
// Vuex Store
import store from './store/store'
import App from "./App.vue";
/******** Frontend Layouts ********/
import LayoutHeader from './views/frontend/Header'
import LayoutFooter from './views/frontend/Footer'
import LayoutHeaderservice from './views/frontend/provider/header'
import LayoutHeadercustomer from './views/frontend/customer/header'
import Customersidebar from './views/frontend/customer/customersidebar'
import Providersidebar from './views/frontend/pages/service/providersidebar'
import Layoutheader5 from './views/frontend/dashboard/home5/header'
import Layoutfooter5 from './views/frontend/dashboard/home5/footer'
import Layoutheader4 from './views/frontend/dashboard/home4/header'
import Layoutfooter4 from './views/frontend/dashboard/home4/footer'
import Layoutheader3 from './views/frontend/dashboard/home3/header'
import Layoutfooter3 from './views/frontend/dashboard/home3/footer'
import Layoutheader2 from './views/frontend/dashboard/home2/header'
import Layoutfooter2 from './views/frontend/dashboard/home2/footer'
/******** Home *********/
import Work from './views/frontend/dashboard/home/work'
import Popular from './views/frontend/dashboard/home/popular'
import Category from './views/frontend/dashboard/home/category'
import Hero from './views/frontend/dashboard/home/hero'
import App1 from './views/frontend/dashboard/home/app'
/******** Home5 *********/
import Home5work from './views/frontend/dashboard/home5/work1'
import Home5popular from './views/frontend/dashboard/home5/popular1'
import Home5category from './views/frontend/dashboard/home5/category1'
/******** Home4 *********/
import App2 from './views/frontend/dashboard/home4/app2'
import Work2 from './views/frontend/dashboard/home4/work2'
import Popular2 from './views/frontend/dashboard/home4/popular2'
import Feature2 from './views/frontend/dashboard/home4/feature2'
/********* Home3 *********/
import Home3app from './views/frontend/dashboard/home3/home3app'
import Home3work from './views/frontend/dashboard/home3/home3work'
import Home3service from './views/frontend/dashboard/home3/home3service'
import Home3category from './views/frontend/dashboard/home3/home3category'
import Banner from './views/frontend/dashboard/home3/banner'
/********* Home2 ***********/
import Home2app from './views/frontend/dashboard/home2/home2app'
import Home2work from './views/frontend/dashboard/home2/home2work'
import Home2service from './views/frontend/dashboard/home2/home2service'
import Home2category from './views/frontend/dashboard/home2/Home2category'
import Home2search from './views/frontend/dashboard/home2/home2search'
/******** Chat *********/
import Chatleft from './views/frontend/pages/chat/chatleft'
import Chatright from './views/frontend/pages/chat/chatright'
/******** Service *********/
import Myservicemodal from './views/frontend/pages/service/myservicemodal'
/******** Provider *********/
import Providertable from './views/frontend/provider/availability/providertable'
import Providerbooking1 from './views/frontend/provider/booking/providerbooking1'
import Providerdash1 from './views/frontend/provider/dashboard/Providerdash1'
/******* Setting **********/
import Settingform from './views/frontend/provider/setting/settingform'
/******* Subscription ********/
import Enterprice from './views/frontend/provider/subscription/enterprice'
import Plandetail from './views/frontend/provider/subscription/plandetail'
import Subscriptiontable from './views/frontend/provider/subscription/subscriptiontable'
/******* Wallet ********/
import Withdraw from './views/frontend/provider/wallet/withdraw'
import Wallettable from './views/frontend/provider/wallet/wallettable'
/******* Search **********/
import Searchsidebar from './views/frontend/pages/search/searchsidebar'
import Searchtable from './views/frontend/pages/search/searchtable'
/******* Service detail ********/
import Servicesidebar from './views/frontend/pages/servicedetail/servicesidebar'
import Popularservice from './views/frontend/pages/servicedetail/popularservice'
import Serviceview from './views/frontend/pages/servicedetail/serviceview'
/******* Booking *******/
import Bookinglist from './views/frontend/customer/booking/bookinglist'
/******* Setting ********/
import Settingtable from './views/frontend/customer/setting/settingtable'
/******* Wallet customer *********/
import Addwallet from './views/frontend/customer/wallet/addwallet'
import Wallettable1 from './views/frontend/customer/wallet/wallettable'
/*********Breadcrumb ********/
import breadcrumb1 from './views/frontend/breadcrumb/about'
import breadcrumb2 from './views/frontend/breadcrumb/categories'
import breadcrumb3 from './views/frontend/breadcrumb/contactus'
import breadcrumb4 from './views/frontend/breadcrumb/faq'
import breadcrumb5 from './views/frontend/breadcrumb/search'
/******** Admin Layouts *********/
import Layoutheaderadmin from './views/admin/header'
import Layoutheadersetting from './views/admin/setting/header'
import Layoutsidebaradmin from './views/admin/sidebar'
import Bookingnav from './views/admin/booking/bookingnav'
import Bookingheader from './views/admin/booking/Bookingheader'
import Categoryfilter from './views/admin/categoryfilter'
import Settingsidebar from './views/admin/setting/settingsidebar'
import Smssetting from './views/admin/setting/smssetting'
import Wallettab from './views/admin/wallet/tab'
import Viewcontent from './views/admin/invoices/view/viewcontent'
import Viewcontent1 from './views/admin/invoices/view/viewcontent1'
import Viewcontent2 from './views/admin/invoices/view/viewcontent2'
import Viewcontent3 from './views/admin/invoices/view/viewcontent3'
import Userheader from './views/admin/user/userheader'
import Settingnav from './views/admin/invoices/setting/settingnav'
import Subcategoryfilter from './views/admin/subcategory/subcategoryfilter'
import Socialcontent from './views/admin/setting/social/socialcontent'
import Socialcontent1 from './views/admin/setting/social/socialcontent1'
import Socialcontent2 from './views/admin/setting/social/socialcontent2'
import Sociallinkcontent from './views/admin/setting/socialink/sociallinkcontent'
import Sociallinkcontent1 from './views/admin/setting/socialink/sociallinkcontent1'
import Generalcontent from './views/admin/setting/general/generalcontent'
import Generalcontent1 from './views/admin/setting/general/generalcontent1'
import Providerfilter from './views/admin/provider/filter'
import Servicefilter from './views/admin/service/servicefilter'
import Detailcontent from './views/admin/service/detail/detailcontent'
import Detailcontent1 from './views/admin/service/detail/detailcontent1'
import Seocontent from './views/admin/setting/seo/seosetting'
import Reviewfilter from './views/admin/reviewfilter'
import Paymentcontent from './views/admin/setting/payment/paymentcontent'
import Paymentcontent1 from './views/admin/setting/payment/paymentcontent1'
import Paymentfilter from './views/admin/payment/filter'
import Othercontent from './views/admin/setting/other/othercontent'
import Othercontent1 from './views/admin/setting/other/othercontent1'
import Othercontent2 from './views/admin/setting/other/othercontent2'
import Invoicefilter from './views/admin/invoices/invoicefilter'
import Invoiceheader from './views/admin/invoices/invoiceheader'
import Invoicetab from './views/admin/invoices/invoicetab'
import Invoicewidget from './views/admin/invoices/invoicewidget'
import Invoicemodal from './views/admin/invoices/invoicemodal'
import Gridtab from './views/admin/invoices/grid/gridtab'
import Gridfilter from './views/admin/invoices/grid/gridfilter'
import Gridheader from './views/admin/invoices/grid/gridheader'
import Adminindexpayment from './views/admin/dashboard/payment'
import Adminindexrecentbooking from './views/admin/dashboard/recentbooking'
import Adminindexwidget from './views/admin/dashboard/widget'
import Homecontent from './views/admin/setting/home/homecontent'
import Homecontent1 from './views/admin/setting/home/homecontent1'
import Homecontent2 from './views/admin/setting/home/homecontent2'
import Homecontent3 from './views/admin/setting/home/homecontent3'
import Homecontent4 from './views/admin/setting/home/homecontent4'
import Frontcontent from './views/admin/setting/front/frontcontent'
import Frontcontent1 from './views/admin/setting/front/frontcontent1'
import Editinvoicecontent from './views/admin/invoices/edit/editcontent'
import Editinvoicecontent1 from './views/admin/invoices/edit/editcontent1'
import Editinvoicecontent2 from './views/admin/invoices/edit/editcontent2'
import Editinvoicecontent3 from './views/admin/invoices/edit/editcontent3'
import Editinvoiceheader from './views/admin/invoices/edit/editheader'
import Edittable from './views/admin/invoices/edit/edittable'
import Editmodal from './views/admin/invoices/edit/editmodal'
import Addcontent from './views/admin/invoices/add/addcontent'
import Addcontent1 from './views/admin/invoices/add/addcontent1'
import Addcontent2 from './views/admin/invoices/add/addcontent2'
import Addcontent3 from './views/admin/invoices/add/addcontent3'
import Addcontent4 from './views/admin/invoices/add/addcontent4'
import Addcontent5 from './views/admin/invoices/add/addcontent5'
import Addtable from './views/admin/invoices/add/addtable'

import Leftfooter from './views/admin/setting/footersetting/leftfooter'
import Leftfooter2 from './views/admin/setting/footersetting/leftfooter2'
import Leftfooter1 from './views/admin/setting/footersetting/leftfooter1'
import Rightfooter from './views/admin/setting/footersetting/rightfooter'
import Rightfooter1 from './views/admin/setting/footersetting/rightfooter1'
import Headertranslation from './views/admin/headertranslation'



import loader from './views/loader'
import './assets/css/bootstrap/css/bootstrap.min.css'
import './assets/css/bootstrap/js/bootstrap.bundle.min.js'
import './assets/css/aos.css'
import './assets/css/aos.js'
import './assets/fontawesome/css/fontawesome.min.css'
import './assets/fontawesome/css/all.min.css'
import VueSelect from 'vue3-select2-component'
import DatePicker from 'vue3-datepicker'
import 'moment'
import jquery from 'jquery'; 
window.$ = jquery
if(window.location.href.includes("template/admin")) {
  require('./assets/css/bootstrap-datetimepicker.min.css');
  require('./assets/css/bootstrap-datetimepicker.min.js');
  require('./assets/css/slick.js');
  require('./assets/css/admin.css');
  require('./assets/css/bootstrap4.min.css')
  require('./assets/css/dataTables.bootstrap4.min.css')
  require('./assets/css/jquery.dataTables.min.js')
  require('./assets/css/dataTables.bootstrap4.min.js')
  require('./assets/css/feather.css')
  require('./assets/css/feather.min.js')
}else {
  require('./assets/css/slick.js');
  require('./assets/css/style.css');
  require('./assets/css/bootstrap4.min.css')
  require('./assets/css/dataTables.bootstrap4.min.css')
  require('./assets/css/jquery.dataTables.min.js')
  require('./assets/css/dataTables.bootstrap4.min.js')
}

const app = createApp(App)
/*************Frontend Layout globally ************/
app.component('layout-header', LayoutHeader);
app.component('layout-footer', LayoutFooter);
app.component('layout-headerservice', LayoutHeaderservice);
app.component('layout-headercustomer', LayoutHeadercustomer);
app.component('customersidebar', Customersidebar);
app.component('providersidebar', Providersidebar);
app.component('layout-header5', Layoutheader5);
app.component('layout-footer5', Layoutfooter5);
app.component('layout-header4', Layoutheader4);
app.component('layout-footer4', Layoutfooter4);
app.component('layout-header3', Layoutheader3);
app.component('layout-footer3', Layoutfooter3);
app.component('layout-header2', Layoutheader2);
app.component('layout-footer2', Layoutfooter2);
/*************Home globally **************/
app.component('work', Work);
app.component('popular', Popular);
app.component('category', Category);
app.component('hero', Hero);
app.component('app', App1);
/*************Home5 globally ************/
app.component('home5work', Home5work);
app.component('home5popular', Home5popular);
app.component('home5category', Home5category);
/*************Home4 globally ***********/
app.component('app2', App2);
app.component('work2', Work2);
app.component('popular2', Popular2);
app.component('feature2', Feature2);
/*************Home3 globally **********/
app.component('home3app', Home3app);
app.component('home3work', Home3work);
app.component('home3service', Home3service);
app.component('home3category', Home3category);
app.component('banner', Banner);
/*************Home2 ************/
app.component('home2app', Home2app);
app.component('home2work', Home2work);
app.component('home2service', Home2service);
app.component('home2category', Home2category);
app.component('home2search', Home2search);
/*************Chat globally *************/
app.component('chatleft', Chatleft);
app.component('chatright', Chatright);
/*************Service globally **********/
app.component('myservicemodal', Myservicemodal);
/*************Provider globally *********/
app.component('providertable', Providertable);
app.component('providerbooking1', Providerbooking1);
app.component('providerdash1', Providerdash1);
/*************Setting globally *********/
app.component('settingform', Settingform);
/*************Subscription globally ************/
app.component('enterprice', Enterprice);
app.component('plandetail', Plandetail);
app.component('subscriptiontable', Subscriptiontable);
/*************Wallet ************/
app.component('withdraw', Withdraw);
app.component('wallettable', Wallettable);
/*************Search ***********/
app.component('searchsidebar', Searchsidebar);
app.component('searchtable', Searchtable);
/**********Service detail ********/
app.component('servicesidebar', Servicesidebar);
app.component('popularservice', Popularservice);
app.component('serviceview', Serviceview);
/**********Customer **********/
app.component('bookinglist', Bookinglist);
/**********Setting *********/
app.component('settingtable', Settingtable);
/**********Wallet customer *********/
app.component('addwallet', Addwallet);
app.component('wallettable1', Wallettable1);
/*************Breadcrumb globally **********/
app.component('breadcrumb1', breadcrumb1);
app.component('breadcrumb2', breadcrumb2);
app.component('breadcrumb3', breadcrumb3);
app.component('breadcrumb4', breadcrumb4);
app.component('breadcrumb5', breadcrumb5);
/*************Admin Layout globally ************/
app.component('layout-headeradmin', Layoutheaderadmin);
app.component('layout-sidebaradmin', Layoutsidebaradmin);
app.component('layout-headersetting', Layoutheadersetting);
app.component('bookingnav', Bookingnav);
app.component('bookingheader', Bookingheader);
app.component('categoryfilter', Categoryfilter);
app.component('settingsidebar', Settingsidebar);
app.component('smssetting', Smssetting);
app.component('wallettab', Wallettab);
app.component('viewcontent', Viewcontent);
app.component('viewcontent1', Viewcontent1);
app.component('viewcontent2', Viewcontent2);
app.component('viewcontent3', Viewcontent3);
app.component('userheader', Userheader);
app.component('invoicesetting', Settingnav);
app.component('subcategoryfilter', Subcategoryfilter);
app.component('socialcontent', Socialcontent);
app.component('socialcontent1', Socialcontent1);
app.component('socialcontent2', Socialcontent2);
app.component('sociallinkcontent', Sociallinkcontent);
app.component('sociallinkcontent1', Sociallinkcontent1);
app.component('generalcontent', Generalcontent);
app.component('generalcontent1', Generalcontent1);
app.component('providerfilter', Providerfilter);
app.component('servicefilter', Servicefilter);
app.component('detailcontent', Detailcontent);
app.component('detailcontent1', Detailcontent1);
app.component('seocontent', Seocontent);
app.component('reviewfilter', Reviewfilter);
app.component('paymentcontent', Paymentcontent);
app.component('paymentcontent1', Paymentcontent1);
app.component('paymentfilter', Paymentfilter);
app.component('othercontent', Othercontent);
app.component('othercontent1', Othercontent1);
app.component('othercontent2', Othercontent2);
app.component('invoicefilter', Invoicefilter);
app.component('invoiceheader', Invoiceheader);
app.component('invoicetab', Invoicetab);
app.component('invoicewidget', Invoicewidget);
app.component('invoicemodal', Invoicemodal);
app.component('gridtab', Gridtab);
app.component('gridfilter', Gridfilter);
app.component('gridheader', Gridheader);
app.component('adminpayment', Adminindexpayment);
app.component('adminrecentbooking', Adminindexrecentbooking);
app.component('adminindexwidget', Adminindexwidget);
app.component('homecontent', Homecontent);
app.component('homecontent1', Homecontent1);
app.component('homecontent2', Homecontent2);
app.component('homecontent3', Homecontent3);
app.component('homecontent4', Homecontent4);
app.component('frontcontent', Frontcontent);
app.component('frontcontent1', Frontcontent1);
app.component('editinvoicecontent', Editinvoicecontent);
app.component('editinvoicecontent1', Editinvoicecontent1);
app.component('editinvoicecontent2', Editinvoicecontent2);
app.component('editinvoicecontent3', Editinvoicecontent3);
app.component('editinvoiceheader', Editinvoiceheader);
app.component('editinvoicetable', Edittable);
app.component('editinvoicemodal', Editmodal);
app.component('addinvoicecontent', Addcontent);
app.component('addinvoicecontent1', Addcontent1);
app.component('addinvoicecontent2', Addcontent2);
app.component('addinvoicecontent3', Addcontent3);
app.component('addinvoicecontent4', Addcontent4);
app.component('addinvoicecontent5', Addcontent5);
app.component('addinvoicetable', Addtable);

app.component('leftfooter', Leftfooter);
app.component('leftfooter2', Leftfooter2);
app.component('leftfooter1', Leftfooter1);
app.component('rightfooter', Rightfooter);
app.component('rightfooter1', Rightfooter1);
app.component('headertranslation', Headertranslation);


/************Plugin globally ***********/
app.component('Loader', loader);
app.component('vue-select', VueSelect);
app.component('datepicker', DatePicker);
app.use(router)
app.use(store)
.mount('#app');
