<template>
<!-- Search Filter -->
                    <div class="card filter-card" id="filter_inputs">
                        <div class="card-body pb-0">
                            <form action="#"> 
                                <div class="row filter-row">
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Provider</label>
                                            <vue-select :options="provider" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <vue-select :options="status" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <div class="cal-icon">
                                                <datepicker v-model="startdate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <div class="cal-icon">
                                                <datepicker v-model="enddate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <button class="btn btn-primary btn-block w-100" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /Search Filter -->
</template>
<script>
    import { ref } from 'vue'
    const currentDate = ref(new Date())
    const currentDate1 = ref(new Date())
	export default {
	mounted() {
	},
    data() {
        return {
            startdate: currentDate,
            enddate: currentDate1,
            provider: ["Select Provider", "Thomas Herzberg", "Matthew Garcia", "Yolanda Potter", "Ricardo Flemings", "Maritza Wasson"],
            status: ["Select Status", "Pending", "Inprogress", "Completed (Provider)", "Accepted", "Rejected", "Cancelled (Provider)"]
        }
    }
	}
	</script>