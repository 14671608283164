<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-lg-8 m-auto">
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="page-title">FAQ</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="row">
                                <div class=" col-lg-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">FAQ</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="faq" class="check" type="checkbox" checked="">
                                                        <label for="faq" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="faq">
                                                <div class="row counts-list">
                                                    <div class="col-md-11">
                                                        <div class="cards">
                                                            <div class="form-group">
                                                                <label>Title</label>
                                                                <input type="text" class="form-control" value="Anim pariatur cliche reprehenderit?">
                                                            </div>
                                                            <div class="form-group mb-0">
                                                                <label>Content</label>
                                                                <textarea class="form-control">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row counts-list">
                                                    <div class="col-md-11">
                                                        <div class="cards">
                                                            <div class="form-group">
                                                                <label>Title</label>
                                                                <input type="text" class="form-control" value="Anim pariatur cliche reprehenderit?">
                                                            </div>
                                                            <div class="form-group mb-0">
                                                                <label>Content</label>
                                                                <textarea class="form-control">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row counts-list">
                                                    <div class="col-md-11">
                                                        <div class="cards">
                                                            <div class="form-group">
                                                                <label>Title</label>
                                                                <input type="text" class="form-control" >
                                                            </div>
                                                            <div class="form-group mb-0">
                                                                <label>Content</label>
                                                                <textarea class="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <a class="btn  btn-success addfaq"><i class="fa fa-plus me-2"></i>Add New</a>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update">Submit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import Vue from 'vue'
    export default {
      mounted() {
        $(document).on("click",".addfaq",function () {
	var experiencecontent = '<div class="row counts-list">' +
	'<div class="col-md-11">' +
	'<div class="cards">' +
	'<div class="form-group">' +
	'<label>Title</label>' +
	'<input type="text" class="form-control" >' +
	'</div>' +
	'<div class="form-group mb-0">' +
	'<label>Content</label>' +
	'<textarea class="form-control"></textarea>' +
	'</div>' +
	'</div>' +
	'</div>' +
	'<div class="col-md-1">' +
	'<a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">' +
	'<i class="far fa-trash-alt "></i> ' +
	'</a>' +
	'</div>' +
	'</div> ';
	
	$(".faq").append(experiencecontent);
	return false;
});

$(".faq").on('click','.delete_review_comment', function () {
	$(this).closest('.counts-list').remove();
	return false;
});

$(".settings-form").on('click','.trash', function () {
	$(this).closest('.links-cont').remove();
	return false;
});

$(document).on("click",".add-links",function () {
	var experiencecontent = '<div class="row form-row links-cont">' +
		'<div class="form-group d-flex">' +
			'<button class="btn social-icon"><i class="feather-github"></i></button>' +
			'<input type="text" class="form-control" placeholder="Social Link">' +
			'<div><a href="javascript:void(0);" class="btn trash"><i class="feather-trash-2"></i></a></div>' +
		'</div>' +
	'</div>';
	
	$(".settings-form").append(experiencecontent);
	return false;
});
      },
    }
  </Script>