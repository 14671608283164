<template>
<section class="download-app">
        <div class="container">
            <div class="app-world">
                <div class="app-world-box">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-12">
                            <div class="col-md-12">
                                <div class="heading aos" data-aos="fade-up">
                                    <h2>Download Our App</h2>
                                    <span>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.Aliquam lorem ante, dapibus in, viverra quis, feugiat </span>
                                </div>
                            </div>
                            <div class="downlaod-set aos" data-aos="fade-up">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);"><img src="../../../../assets/img/app-gp.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);"><img src="../../../../assets/img/app-ap.png" alt="img"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7 col-12">
                            <div class="appimg-set aos" data-aos="fade-up">
                                <img src="../../../../assets/img/down-app.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>