<template>

	<div class="main-wrapper">

    <layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="section-header text-center">
                        <h2>Book Service</h2>
                    </div>
                    <!-- <form> -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Issue title<span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" v-model="createobjwalkin.issue_title" placeholder="Enter the Issue title" autocomplete="off">
                                </div>                            
                            </div>
                            
                            <!-- <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Service amount</label>
                                    <input class="form-control" type="text" value="$100" readonly>
                                </div>
                            </div> -->
                            <div class="col-lg-6">
                               <div class="form-group">
                                    <label>Address On <span class="text-danger">*</span></label>
                                    <!-- <input class="form-control hasDatepicker" type="date" v-model="createobjwalkin.address_on"> -->
                                    <select class="selectbox select form-select" v-model="selectedDateTimeSlot">
                                        <option v-for="item in techuserinfo" v-bind:key="item.id" v-bind:value="item">{{item.available_date}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Contact Number<span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" v-model="createobjwalkin.contact_number" placeholder="Enter Contact Number" autocomplete="off">
                                </div>                            
                            </div>
                            <div class="col-lg-6">
                               <div v-if="selectedDateTimeSlot != null">
                              
                                <p>choose your slot :</p>
                                <!-- <p>choose your slot : {{ selectedTime }}</p> -->
                                <button :class=" (selectedTime == '0') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '0'" v-if="selectedDateTimeSlot.t00 == 1">00:00 am to 01:00 am</button>
                                <button :class=" (selectedTime == '1') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '1'" v-if="selectedDateTimeSlot.t01 == 1">01:00 am to 02:00 am</button>
                                <button :class=" (selectedTime == '2') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '2'" v-if="selectedDateTimeSlot.t02 == 1">02:00 am to 03:00 am</button>
                                <button :class=" (selectedTime == '3') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '3'" v-if="selectedDateTimeSlot.t03 == 1">03:00 am to 04:00 am</button>
                                <button :class=" (selectedTime == '4') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '4'" v-if="selectedDateTimeSlot.t04 == 1">04:00 am to 05:00 am</button>
                                <button :class=" (selectedTime == '5') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '5'" v-if="selectedDateTimeSlot.t05 == 1">05:00 am to 06:00 am</button>

                                <button :class=" (selectedTime == '6') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '6'" v-if="selectedDateTimeSlot.t06 == 1">06:00 am to 07:00 am</button>
                                <button :class=" (selectedTime == '7') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '7'" v-if="selectedDateTimeSlot.t07 == 1">07:00 am to 08:00 am</button>
                                <button :class=" (selectedTime == '8') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '8'" v-if="selectedDateTimeSlot.t08 == 1">08:00 am to 09:00 am</button>
                                <button :class=" (selectedTime == '9') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '9'" v-if="selectedDateTimeSlot.t09 == 1">09:00 am to 10:00 am</button>
                                <button :class=" (selectedTime == '10') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '10'" v-if="selectedDateTimeSlot.t10 == 1">10:00 am to 11:00 am</button>
                                <button :class=" (selectedTime == '11') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '11'" v-if="selectedDateTimeSlot.t11 == 1">11:00 am to 12:00 pm</button>

                                <button :class=" (selectedTime == '12') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '12'" v-if="selectedDateTimeSlot.t12 == 1">12:00 pm to 01:00 pm</button>
                                <button :class=" (selectedTime == '13') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '13'" v-if="selectedDateTimeSlot.t13 == 1">01:00 pm to 02:00 pm</button>
                                <button :class=" (selectedTime == '14') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '14'" v-if="selectedDateTimeSlot.t14 == 1">02:00 pm to 03:00 pm</button>
                                <button :class=" (selectedTime == '15') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '15'" v-if="selectedDateTimeSlot.t15 == 1">03:00 pm to 04:00 pm</button>
                                <button :class=" (selectedTime == '16') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '16'" v-if="selectedDateTimeSlot.t16 == 1">04:00 pm to 05:00 pm</button>
                                <button :class=" (selectedTime == '17') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '17'" v-if="selectedDateTimeSlot.t17 == 1">05:00 pm to 06:00 pm</button>
                                
                                <button :class=" (selectedTime == '18') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '18'" v-if="selectedDateTimeSlot.t18 == 1">06:00 pm to 07:00 pm</button>
                                <button :class=" (selectedTime == '19') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '19'" v-if="selectedDateTimeSlot.t19 == 1">07:00 pm to 08:00 pm</button>
                                <button :class=" (selectedTime == '20') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '20'" v-if="selectedDateTimeSlot.t20 == 1">08:00 pm to 09:00 pm</button>
                                <button :class=" (selectedTime == '21') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '21'" v-if="selectedDateTimeSlot.t21 == 1">09:00 pm to 10:00 pm</button>
                                <button :class=" (selectedTime == '22') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '22'" v-if="selectedDateTimeSlot.t22 == 1">10:00 pm to 11:00 pm</button>
                                <button :class=" (selectedTime == '23') ? 'btn btn-secondary' : 'btn btn-primary'" @click="selectedTime = '23'" v-if="selectedDateTimeSlot.t23 == 1">11:00 pm to 12:00 am</button>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group"> 
                                <div v-if="selectedTime != null">
                                <div v-if="selectedDateTimeSlot != null">
                                    <p>{{selectedDateTimeSlot.available_date }} && {{timestatus[selectedTime] }} </p>
                                    <button @click="checkdateAvailable"> check time slot availabilty</button>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <div class="text-center">
                                        <div id="load_div"></div>
                                    </div>
                                    <label>Description</label>
                                    <textarea class="form-control" rows="4" v-model="createobjwalkin.description" placeholder="Describe the issue details"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <div class="text-center">
                                        <div id="load_div"></div>
                                    </div>
                                    <label>Address</label>
                                    <textarea class="form-control" rows="4" v-model="createobjwalkin.address" placeholder="Enter Address"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Country <span class="text-danger">*</span></label>
                                    <select class="form-control form-select" v-model="createobjwalkin.country">
                                        <option :value="item.id" :key="index" v-for="(item,index) in countrytable ">{{item.country}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>statetable <span class="text-danger">*</span></label>
                                    <select class="form-control form-select" v-model="createobjwalkin.state">
                                        <option :value="item.id" :key="index" v-for="(item,index) in stateList ">{{item.state}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>citytable <span class="text-danger">*</span></label>
                                    <select class="form-control form-select" v-model="createobjwalkin.city">
                                        <option :value="item.id" :key="index" v-for="(item,index) in cityList ">{{item.city}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>pincodetable <span class="text-danger">*</span></label>
                                    <select class="form-control form-select" v-model="createobjwalkin.pincode">
                                        <option :value="item.id" :key="index" v-for="(item,index) in pincodeList ">{{item.pincode}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="submit-section">
                            <button v-if="isLogedIn" class="btn btn-primary "  @click="createDataWalkin">Continue to Book</button>
                            <p v-else>Please Login to Book</p>
                        </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>
<script>
import axios from 'axios';
	export default {
        data(){
        return { 
            selectedTime: null,
            selectedDateTimeSlot: null,
            countrytable:[],
            statetable:[],
            citytable:[],
            pincodetable:[],
            techuserinfo:[],
            timestatus:[
                    "00:00 to 01:00",
                    "01:00 to 02:00",
                    "02:00 to 03:00",
                    "03:00 to 04:00",
                    "04:00 to 05:00",
                    "05:00 to 06:00",
                    "06:00 to 07:00",
                    "07:00 to 08:00",
                    "08:00 to 09:00",
                    "09:00 to 10:00",
                    "10:00 to 11:00",
                    "11:00 to 12:00",
                    "12:00 to 13:00",
                    "13:00 to 14:00",
                    "14:00 to 15:00",
                    "15:00 to 16:00",
                    "16:00 to 17:00",
                    "17:00 to 18:00",
                    "18:00 to 19:00",
                    "19:00 to 20:00",
                    "20:00 to 21:00",
                    "21:00 to 22:00",
                    "22:00 to 23:00",
                    "23:00 to 00:00"
                ],
            createobjwalkin:{
                created_by:"",
                contract_id:"",
                past_service_id:"",
                order_type:"",
                issue_title:"",
                specialization:"",
                sub_specialization:"",
                description:"",
                address_on:"",
                share_contact_number:0,
                address:"",
                country:"",
                state:"",
                city:"",
                pincode:"",
                specialization_type:"",
                specialized_choose_from_past:"",
                specialization_choose_myself:"",
                issue_addressing_cost:"",
                technician_cost:"",
                total_cost:"",
                order_price:"",
                discount_amount:"",
                markup_price:"",
                total_price:"",
                gst_tax_amount:"",
                    final_price:"",
                process_status:"",
                time:""
            },
            datecheck:{
                specialization_choose_myself:"",
                address_on:"",
                time:""
            }
        }
    },
    computed: {
	isLogedIn(){
		return localStorage.getItem('is_logegin', false);
	},
    stateList(){
      if(this.createobjwalkin.country != 0 && this.createobjwalkin.country != null)
      return this.statetable.filter(s => s.country_id == this.createobjwalkin.country)
      else
      return []
    },
    
    cityList(){
      if(this.createobjwalkin.state != 0 && this.createobjwalkin.state != null)
      return this.citytable.filter(s => s.state_id == this.createobjwalkin.state)
      else
      return []
    },
    
    pincodeList(){
      if(this.createobjwalkin.city != 0 && this.createobjwalkin.city != null)
      return this.pincodetable.filter(s => s.city_id == this.createobjwalkin.city)
      else
      return []
    },
},
    watch:{
    'createobjwalkin.country': function (newVal, oldVal){
      this.createobjwalkin.state = null;
      this.createobjwalkin.city = null;
      this.createobjwalkin.pincode = null;
    },'createobjwalkin.state': function (newVal, oldVal){
      this.createobjwalkin.city = null;
      this.createobjwalkin.pincode = null;
    },'createobjwalkin.city': function (newVal, oldVal){
      this.createobjwalkin.pincode = null;
    },
    },
    methods : {
        checkdateAvailable(){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }

        this.datecheck.specialization_choose_myself = this.$route.params.tech_id;
        this.datecheck.address_on = this.selectedDateTimeSlot.available_date;
        this.datecheck.time=this.selectedTime;
        
        axios
            .post(this.$store.state.apiEndpoint +"api/checkTechnicainAvailabilty", this.datecheck,auth)
            .then(response => {
            console.log(response.data)
            alert(response.data.message)
      })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                }); 
       },

createDataWalkin(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        let canCallAPi= true;
  if(this.createobjwalkin.issue_title !== "undefined" && this.createobjwalkin.issue_title !== null && this.createobjwalkin.issue_title !== ""){
  
  }else{
    canCallAPi = false;
        // this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Enter Issue title' });
        alert("please enter issue");
        }

        if(this.createobjwalkin.description !== "undefined" && this.createobjwalkin.description !== null && this.createobjwalkin.description !== ""){
 
  }else{
    canCallAPi = false;
        // this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Enter Description of the issue' });
        alert("please enter Description");
        }
//         if(this.createobjwalkin.address_on !== "undefined" && this.createobjwalkin.address_on !== null && this.createobjwalkin.address_on !== ""){
 
//   }else{
//     canCallAPi = false;
//         this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Select Date for service' });
//         }
        if(this.createobjwalkin.contact_number !== "undefined" && this.createobjwalkin.contact_number !== null && this.createobjwalkin.contact_number !== ""){
 
  }else{
    canCallAPi = false
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Enter Contact number' });
        }
        if(this.createobjwalkin.address !== "undefined" && this.createobjwalkin.address !== null && this.createobjwalkin.address !== ""){
           
  }else{
    canCallAPi = false;
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Enter Address Details' });
        }
        if(this.createobjwalkin.country !== "undefined" && this.createobjwalkin.country !== null && this.createobjwalkin.country !== ""){
 
 }else{
    canCallAPi = false;
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Select Country' });
        }  
        if(this.createobjwalkin.state !== "undefined" && this.createobjwalkin.state !== null && this.createobjwalkin.state !== ""){

  }else{
    canCallAPi = false;
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Select State' });
        }
        if(this.createobjwalkin.city !== "undefined" && this.createobjwalkin.city !== null && this.createobjwalkin.city !== ""){
 
  }else{
    canCallAPi = false;
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Select city' });
        }
        if(this.createobjwalkin.pincode !== "undefined" && this.createobjwalkin.pincode !== null && this.createobjwalkin.pincode !== ""){
        
  }else{
    canCallAPi = false
        this.$vs.dialog({ type: "confirm", color: "danger", title: `Missing`,  text: 'Please Select Pincode' });
        }

        if(canCallAPi == true){
            this.createobjwalkin.specialization = this.$route.params.specialization_id;
        this.createobjwalkin.sub_specialization=this.$route.params.subspecialization_id;
        this.createobjwalkin.specialization_type =3;
        this.createobjwalkin.specialization_choose_myself = this.$route.params.tech_id;
        this.createobjwalkin.order_type=1;
        this.createobjwalkin.process_status=0;
        this.createobjwalkin.skillsetinfo_id = this.$route.params.skillsetinfo_id;
        this.createobjwalkin.address_on = this.selectedDateTimeSlot.available_date;
        this.createobjwalkin.time=this.selectedTime;
        this.createobjwalkin.share_contact_number=1;
        
          axios
            .post(this.$store.state.apiEndpoint +"api/Enquiry", this.createobjwalkin,auth)
            .then(response => {
                    if (response.data.status==true){
                    alert(response.data.message)
                    // console.log(response.data)                
                    // this.$router.push("/thanks-msg"); 
                    
                    // this.resetwalkin();
                    }else{
                    alert(response.data.message)
                    }
                
                })
                .catch(e => {
                  console.log(e,"jkdfgsf");
                  alert("time slot just before only booked. try some other slots",e);
                }); 
        }else{
            alert("not submitted",response.data.status)
        }

},
get_countryinfo(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/CountryMasterr",auth)
                .then(response => {
                  this.countrytable=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error count");
                });
        },
        get_stateinfo(){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/StateMasterr",auth)
                .then(response => {
                  this.statetable=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error state");
                });
        },
get_cityinfo(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/CityMaster",auth)
                .then(response => {
                  this.citytable=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error city");
                });
        },
get_pincodeinfo(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/PincodeMaster",auth)
                .then(response => {
                  this.pincodetable=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error pin");
                });
        },
get_techuserinfo(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .post(this.$store.state.apiEndpoint +"api/gettechdatedetails/"+this.$route.params.skillsetinfo_userid,{}, auth)

                .then(response => {
                  this.techuserinfo=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error tech");
                });
        },

        
    },

    mounted() {
    this.get_countryinfo();
    this.get_cityinfo();
    this.get_stateinfo();
    this.get_pincodeinfo();
    this.get_techuserinfo();
    },
	}
</script>