<template>
<ul class="nav nav-tabs menu-tabs">
					<li class="nav-item ">
						<router-link class="nav-link" to="/admin/sms-setting">Nexmo</router-link>
					</li>
					<li class="nav-item ">
						<router-link class="nav-link" to="/admin/twilio">Twilio</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/admin/factor">2Factor</router-link>
					</li>
				</ul>
</template>