<template>
<ul class="nav nav-tabs menu-tabs">
					<li class="nav-item" :class="{'active': currentPath === '/admin/total-report'}">
						<router-link class="nav-link" to="/admin/total-report">All Booking <span class="badge badge-primary">550</span></router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/pending-report'}">
						<router-link class="nav-link" to="/admin/pending-report">Pending <span class="badge badge-primary">125</span></router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/inprogress-report'}">
						<router-link class="nav-link" to="/admin/inprogress-report">InProgress <span class="badge badge-primary">86</span></router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/complete-report'}">
						<router-link class="nav-link" to="/admin/complete-report">Completed <span class="badge badge-primary">89</span></router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/reject-report'}">
						<router-link class="nav-link" to="/admin/reject-report">Rejected <span class="badge badge-primary">101</span></router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/cancel-report'}">
						<router-link class="nav-link" to="/admin/cancel-report">Canceled <span class="badge badge-primary">121</span></router-link>
					</li>
</ul>
</template>
<script>
export default {
	computed: {
        currentPath() {
            return this.$route.name;
        }
    },
mounted() {
  },
data() {
	return {
	  activeClass: 'active',
	};
			//  isactive : true
  },
}
</script>
<style>
.sidebar-menu ul li a.active {
	background-color: #ff0080;
    color: #fff;
}
</style>