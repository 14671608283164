<template>
<!-- Page Header -->
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">Invoices</h3>
                        </div>
                        <div class="col-auto">
                            <router-link to="/admin/invoices" class="invoices-links active">
                                <i class="feather feather-list"></i>
                            </router-link>
                            <router-link to="/admin/invoice-grid" class="invoices-links">
                                <i class="feather feather-grid"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
</template>