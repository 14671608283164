<template>
    <div class="col-md-6 d-flex">
                        
        <!-- Recent Bookings -->
        <div class="card card-table flex-fill">
            <div class="card-header">
                <h4 class="card-title">Recent Bookings</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-center">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Date</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in list" :key="item.id">
                                <td class="text-nowrap">
                                    <img class="avatar-xs rounded-circle" :src="loadImg(item.img)" alt="User Image"> {{item.customer}}
                                </td>
                                <td class="text-nowrap">{{item.date}}</td>
                                <td>{{item.service}}</td>
                                <td>
                                    <span class="badge bg-danger inv-badge">{{item.status}}</span>
                                </td>
                                <td>
                                    <div class="font-weight-600">{{item.date1}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- /Recent Bookings -->
        
    </div>
</template>
<script>
	import list from '../../../assets/json/admin/index/list.json'
    import util from '../../../assets/utils/util'
	const images = require.context('../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
	export default {
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            list: list
        }
    }
	}
	</script>
