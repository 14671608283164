<template>
<div class="row">
	<div class="col-lg-4 col-md-6" v-for="item in subspecializationinfoByid" :key="item.id">
		<div class="service-widget">
			<div class="service-img">
				<router-link :to='"/service-details/"+$route.params.catid+"/"+item.id'>
					<img class="img-fluid serv-img" alt="Service Image" :src="$store.state.apiImage+item.image">
					
				</router-link>
				<!-- <div class="fav-btn">
					<a href="javascript:void(0)" class="fav-icon">
						<i class="fas fa-heart"></i>
					</a>
				</div> -->
				<div class="item-info">
					<div class="service-user">
						<a href="javascript:void(0);">
							<img :src="$store.state.apiImage+item.specializationinfo.image" alt="">
						</a>	
						<!-- <span class="service-price ms-1">{{item.id}}</span> -->
					</div>
					<div class="cate-list">
						<router-link class="bg-yellow" :to='"/service-details/"+$route.params.catid+"/"+item.id'>{{item.specializationinfo.specialization}}</router-link>
					</div>
				</div>
			</div>
			<div class="service-content">
				<h3 class="title">
					<router-link to="/service-details">{{item.sub_specialization}}</router-link>
				</h3>
				<!-- <div class="rating mapgridrating1">	
					<i class="fas fa-star filled"></i>
					<i class="fas fa-star filled"></i>
					<i class="fas fa-star filled"></i>
					<i class="fas fa-star filled"></i>
					<i class="fas fa-star"></i>		
					<span class="d-inline-block average-rating">{{item.id}}</span>
				</div>
				<div class="user-info">
					<div class="row">	
						<span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
							<span>{{item.id}}</span>
						</span>
						<span class="col ser-location">
							<span>{{item.id}}</span> <i class="fas fa-map-marker-alt ms-1"></i>
						</span>
					</div>
				</div> -->
			</div>
		</div>
	</div>
	
</div>
</template>
<script>
import axios from 'axios';
	import searchtable from '../../../../assets/json/website/searchtable.json'
	const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/img/customer', false, /\.png$|\.jpg$/)
	export default {
		data() {
        return {
			subspecializationinfoByid:[],
            searchtable: searchtable
        }
    },
	
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },

		get_subspecializationinfoByid(){
        axios
                .get(this.$store.state.apiEndpoint +"api/getsubspecializationByid/"+this.$route.params.catid)
                .then(response => {
                  this.subspecializationinfoByid=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });
        },
    },
	mounted() {
		this.get_subspecializationinfoByid();
	},
	
	}
	</script>