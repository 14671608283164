<template>
<!-- Invoice Item -->
								<div class="invoice-issues-box">
									<div class="row">
										<div class="col-lg-4 col-md-4">
											<div class="invoice-issues-date">
												<p>Issue Date : 27 Jul 2022</p>
											</div>
										</div>
										<div class="col-lg-4 col-md-4">
											<div class="invoice-issues-date">
												<p>Due Date : 27 Aug 2022</p>
											</div>
										</div>
										<div class="col-lg-4 col-md-4">
											<div class="invoice-issues-date">
												<p>Due Amount : ₹ 1,54,22 </p>
											</div>
										</div>
									</div>
								</div>
								<!-- /Invoice Item -->
</template>