<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">How It Works</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="works" class="check" type="checkbox" checked="">
                                                        <label for="works" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                
                                                <div class="form-group">
                                                    <h6 class="form-heads mb-0">Step 1</h6>
                                                </div>
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <p class="settings-label">Image</p>
                                                    <div class="settings-btn">
                                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                        <label for="file" class="upload">
                                                            <i class="fa fa-upload"></i>
                                                        </label>
                                                    </div>
                                                    <div class="upload-images ">
                                                        <img src="../../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <h6 class="form-heads mb-0">Step 2</h6>
                                                </div>
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <p class="settings-label">Image</p>
                                                    <div class="settings-btn">
                                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                        <label for="file" class="upload">
                                                            <i class="fa fa-upload"></i>
                                                        </label>
                                                    </div>
                                                    <div class="upload-images ">
                                                        <img src="../../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <h6 class="form-heads mb-0">Step 3</h6>
                                                </div>
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <p class="settings-label">Image</p>
                                                    <div class="settings-btn">
                                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                        <label for="file" class="upload">
                                                            <i class="fa fa-upload"></i>
                                                        </label>
                                                    </div>
                                                    <div class="upload-images ">
                                                        <img src="../../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="form-groupbtn">
                                                    <a class="btn btn-update me-2">Update</a>
                                                    <a class="btn btn-cancel">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
</template>