<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Ratings</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript: void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <reviewfilter></reviewfilter>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="reviewreport">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Provider</th>
                                                    <th>Service</th>
                                                    <th>Type Name</th>
                                                    <th>Ratings</th>
                                                    <th>Comments</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in reviewreport" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name}}</a>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg1(item.img1)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name1}}</a>
                                                        </span>
                                                    </td>
                                                    <td>{{item.name2}}</td>
                                                    <td>{{item.name3}}</td>
                                                    <td>{{item.rating}}</td>
                                                    <td>{{item.status}}</td>
                                                    <td class="text-end">
                                                        <a data-id="40" href="javascript:void(0);" class="btn btn-sm bg-danger-light me-2 delete_review_comment">	<i class="far fa-trash-alt me-1"></i> Delete</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import reviewreport from '../../assets/json/admin/reviewreport.json'
    import util from '../../assets/utils/util'
	const images = require.context('../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../assets/admin_img/provider', false, /\.png$|\.jpg$/)
	export default {
    mounted() {
    util.datatable('#reviewreport')  
    },
    methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            reviewreport: reviewreport
        }
    }
	}
	</script>