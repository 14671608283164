<template>
    <!-- <div>
        <div v-if="(users_approvedtechnician.length!=null)">
        <p>tech count: {{users_approvedtechnician.length}}</p></div>
        <div v-else>
            <p>hi{{users_approvedtechnician.length}}</p>
        </div>

    </div> -->
    <div>
        <div class="form-group">
            <label>Choose City  <span class="text-danger">*</span></label>
            <select class="form-control form-select" v-model="searchcity">
                <option :value="item.id" :key="index" v-for="(item,index) in citytable " >{{item.city}}</option>
            </select>
        </div>
        <!-- {{ searchcity }} -->
        <button class="btn btn-primary "  @click="SearchTech">Search Technicain</button>
        <!-- <div v-if="SearchTech==''">
			<h6 align="center">sorry for the inconvience. this Technician is not available right now.</h6> 
		</div> -->

    </div>
    
<div class="col-lg theiaStickySidebar" v-for="item in users_approvedtechnician" :key="item.id">
    <div class="stickyside">
                    <div class="card provider-widget clearfix">
                        <div class="card-body" >
                            <div class="about-author">
                                <div class="about-provider-img">
                                    <div class="provider-img-wrap">
                                        <a href="javascript:void(0);">
                                            <img class="img-fluid rounded-circle" alt="" :src="$store.state.apiImage+item.userinfo.userprofileimage">
                                        </a>
                                    </div>
                                </div>
                                <div class="provider-details">
                                    <a href="javascript:void(0);" class="ser-provider-name">{{item.userinfo.name}}</a>
                                    <p class="last-seen"><i class="fas fa-circle online"></i> {{item.skillsetinfo.wages}} {{item.skillsetinfo.currencyinfo ? item.skillsetinfo.currencyinfo.currency : "Error"}}</p>
                                    <p class="text-muted mb-1">{{item.skillsetinfo.wages_type}}</p>
                                </div>
                            </div>
                            <hr>
                            <div>
                            <div class="service-book" v-if="isLogedIn">
                                <router-link :to='"/book-service/"+$route.params.specialization_id+"/"+$route.params.subspecialization_id+"/"+item.id+"/skillp/"+item.skillsetinfo.id+"/"+item.skillsetinfo.user_id' 
                                    class="btn btn-primary" > Book Service </router-link>
                            </div>
                            <div class="service-book" v-else>
                                   <!-- <router-link to="" class="btn btn-primary" > login to Book Service </router-link> -->
                                   <a class="header-login btn btn-primary" href="javascript:void(0);" data-bs-toggle="modal" @click="loginScreen = true" data-bs-target="#login_modal">login to Book Service</a>
                        </div></div>
                        </div>
                    </div>
                    
    </div>
</div>
<div>
    <!-- User Register Modal -->
	<div class="modal account-modal fade multi-step" id="user-register" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Join as a User</h3>
					</div>
					<form>
						<div class="form-group form-focus">
							<label class="focus-label">Name</label>
							<input type="text" class="form-control" placeholder="Enter your name" v-model="user.name">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Email address</label>
							<input type="email" class="form-control" placeholder="example@exapmle.com" v-model="user.email">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Create Password</label>
							<input type="password" class="form-control" placeholder="********" v-model="user.password">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Conform Password</label>
							<input type="password" class="form-control" placeholder="********" v-model="user.password_confirmation">
						</div>
						<div class="text-end">
							<a class="forgot-link" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login_modal">Already have an account?</a>
						</div>
						<router-link to="/">
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" data-bs-dismiss="modal" @click="signupUser" >Signup</button>
						</div>
					    </router-link>
					</form>
					
				</div>
			</div>
		</div>
	</div>
	<!-- /User Register Modal -->
	
	<!-- Login Modal -->
	<div class="modal account-modal fade" id="login_modal" >
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Login <span>To Get Service</span></h3>
					</div>
					<!-- <form> -->
						<div class="form-group form-focus">
							<label class="focus-label">Email</label>
							<input type="email" class="form-control" placeholder="example@example.com" v-model="login.email">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Password</label>
							<input type="password" class="form-control" placeholder="********" v-model="login.password">
						</div>
						<div class="text-center dont-have">Forgot Password? <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#ForgotPwd_modal" >Click here</a>
						</div>
						<br>
						<div class="text-end">	
						</div>
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" data-bs-dismiss="modal"  @click="checkLogin">Login</button>
						</div>
						<div class="login-or">	<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="text-center dont-have">Don’t have an account? <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#user-register">Register</a>
						</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Login Modal -->

	<!-- Reset password Modal -->
	<div class="modal account-modal fade" id="ForgotPwd_modal" >
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Login <span>Truelysell</span></h3>
					</div>
						<div class="form-group form-focus">
							<label class="focus-label">Email</label>
							<input type="email" class="form-control" placeholder="example@example.com" v-model="resetpwd.email">
						</div>
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" data-bs-dismiss="modal"  @click="resetPassword">Reset Password</button>
						</div>
						<div class="login-or">	<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="text-center dont-have">Don’t have an account? <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#user-register">Register</a>
						</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Reset password Modal -->
</div>

    <!-- Category Section --
            <section class="subspec">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="heading aos" data-aos="fade-up">
                                        <h2>Technicians list </h2>
                                        <span>What do you need to find?</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="viewall aos" data-aos="fade-up">
                                        <h4><router-link to="/categories">View All <i class="fas fa-angle-right"></i></router-link></h4>
                                        <span>Featured Categories</span>
                                    </div>
                                </div>
                            </div>
                            <div class="catsec">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6" v-for="item in users_approvedtechnician" :key="item.id">
                                        <router-link :to='"/search/"+item.id'>
                                            <div class="cate-widget aos" data-aos="fade-up">
                                                <img :src="$store.state.apiImage+item.userinfo.userprofileimage" alt="" >
                                                <div class="cate-title">
                                                    <h3><span ><i class="fas fa-circle"></i> {{item.userinfo.name}}</span></h3>
                                                </div>
                                                <div class="cate-count">
                                                    <i class="fas fa-clone"></i> {{item.id}}
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="catsec">
                                <div class="row">
                        <div class="card provider-widget clearfix">
                        <div class="card-body" v-for="item in users_approvedtechnician" :key="item.id">
                            <h5 class="card-title">Service Provider</h5>
                            <div class="about-author">
                                <div class="about-provider-img">
                                    <div class="provider-img-wrap">
                                        <a href="javascript:void(0);">
                                            <img class="img-fluid rounded-circle" alt="" :src="$store.state.apiImage+item.userinfo.userprofileimage">
                                        </a>
                                    </div>
                                </div>
                                <div class="provider-details">
                                    <a href="javascript:void(0);" class="ser-provider-name">{{item.userinfo.name}}</a>
                                    <p class="last-seen"><i class="fas fa-circle online"></i> Online</p>
                                    <p class="text-muted mb-1">{{item.joined_date}}</p> 
                                </div>
                            </div>
                            <hr>
                            <div class="provider-info">
                                <p class="mb-1"><i class="far fa-envelope"></i> <a href="javascript:void(0);">thomasherzberg@example.com</a></p>
                                <p class="mb-0"><i class="fas fa-phone-alt"></i> xxxxxxxx33</p>
                            </div>
                        </div>
                    </div>
                                </div></div>



                        </div>
                    </div>
                </div>
            </section>
            !-- /Category Section -->
    </template>
   <script>
   import axios from 'axios';
       export default {
           data() {
           return {
                citytable:[],
                searchcity:"",
                users_approvedtechnician: [],
                loginLocal: false,
                checkbox_remember_me: false,
                activeUserInfos:{},
                page: 0,
                loginScreen: false,
                login:{
                    email:"",
                    password:"",
                },
                resetpwd:{
                    email:""
                },
                user:{
                    name:"",
                    email:"",
                    password:"",
                    password_confirmation:""
                },                
           }
       },
       computed: {
            isLogedIn(){
                return localStorage.getItem('is_logegin', false);
            },
            activeUserInfo () {
                return this.activeUserInfos;
            },
           currentPath() {
               return this.$route.name   
           },
        },
        watch:{
            loginLocal(val){
                if(val == true){

                }
            }
        },
       methods:{
        //     getapproved(){
        //             let x ={
        //         sub_specialization: this.$route.params.subspecialization_id
        //     }
        //    axios
        //            .post(this.$store.state.apiEndpoint +"api/getTechnisianSearch", x)
        //            .then(response => {
        //              this.users_approvedtechnician=response.data;
                    
        //              this.users_approvedtechnician.reverse();
        //              console.log(response);
        //            })
        //            .catch(error => {
        //              console.log(error);
        //              alert("auth error");
        //            });
        //    }, 
           SearchTech(){
                    let x ={
                sub_specialization: this.$route.params.subspecialization_id,
                city: this.searchcity
            }
           axios
                   .post(this.$store.state.apiEndpoint +"api/getTechnisianCitySearch", x)
                   .then(response => {
                     this.users_approvedtechnician=response.data;
                     if(this.users_approvedtechnician == '') {
				       alert("Sorry, Technician is not available right now.");
			      }
                    
                    //  this.users_approvedtechnician.reverse();
                     console.log(response);
                   })
                   .catch(error => {
                     console.log(error);
                     alert("auth error");
                   });
           }, 
           get_cityinfo(){
            const auth = {
                headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
                }
                axios
                .get(this.$store.state.apiEndpoint +"api/CityMaster",auth)
                .then(response => {
                this.citytable=response.data;
                this.citytable.forEach(element => {
                    element.citydet = element.city + " [ "+element.stateinfo.state+ "/" + element.countryinfo.country +"]"
                });
                console.log(response);
                })
                .catch(error => {
                console.log(error);
                // alert("auth error city");
                });
        },
        get_stateinfo(){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/StateMasterr",auth)
                .then(response => {
                  this.statetable=response.data;
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error 4");
                });
        },
get_countryinfo(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/CountryMasterr",auth)
                .then(response => {
                  this.countrytable=response.data;
                  console.log(response);
                })
                 .catch(error => {
                  console.log(error);
                  alert("auth error 5");
                });
        },
           checkLogin () { 
      axios
        .post(
          this.$store.state.apiEndpoint +"api/auth/login",
          this.login
        )
        .then(response => {
			// debugger;
          if (response.data.status){
            localStorage.setItem("is_logegin" , true)
            localStorage.setItem("token" , response.data.access_token)                
            localStorage.setItem("user_id" , response.data.user_id)                
            localStorage.setItem("user_name" , response.data.user_name)                
            localStorage.setItem("user_email" , response.data.user_email)                
            localStorage.setItem("user_userprofileimage" , response.data.user_userprofileimage)                
             console.log(response,"jsdg")
			 this.$router.go();
		  this.loginScreen = false;
		  
		  this.activeUserInfos = {
		uid         : localStorage.user_id,          // From Auth
		displayName : localStorage.user_name, // From Auth	
		useremail 	: localStorage.user_email,
		about       : "",
		photoURL    : localStorage.user_userprofileimage?this.$store.state.apiImage+localStorage.user_userprofileimage:'', // From Auth
		status      : "online",
		userRole    : "User"
		}
		this.loginLocal = true;

          } else {
              
             alert('Login Failed');
          }
        //   window.location.reload();
        })
        .catch(e => {
			// debugger;
          console.log(e);
		  alert('Please enter valid data');
        });
    },
	signupUser () {
      axios
        .post( this.$store.state.apiEndpoint +"api/auth/signup", this.user )
        .then(response => {
		if(response.data.status){
		this.page =  0;
		this.loginScreen = true;
		alert(response.data.message);
		}else{
		alert(response.data.message);
		}
	})
        .catch(e => {
			this.$vs.notify({
				time: 2500,
				title: 'Error',
				text: e.response.data ? e.response.data.message : 'Login Failed',
				iconPack: 'feather',
				icon: 'icon-alert-circle',
				color: 'danger'
			});
        });
    },
	logout () {
      localStorage.removeItem('userInfo')
      localStorage.clear();
	  this.$router.push('/').catch(() => {})
		this.loginLocal = false;
	},
	resetPassword(){
		axios
        .post( this.$store.state.apiEndpoint +"api/password/create", this.resetpwd)
		.then(response => {
            console.log(response.data)
            alert(response.data.message);
      })
	},
       },
           
           mounted() {
            // alert(localStorage.getItem('is_logegin'));
            this.get_countryinfo();
    this.get_cityinfo();
    this.get_stateinfo();
            //    this.getapproved();
            //    this.SearchTech();
               
               this.activeUserInfos = {
		uid         : localStorage.user_id,          // From Auth
		displayName : localStorage.user_name, // From Auth	
		useremail 	: localStorage.user_email,
		about       : "",
		photoURL    : localStorage.user_userprofileimage?this.$store.state.apiImage+localStorage.user_userprofileimage:'', // From Auth
		status      : "online",
		userRole    : "User"
		}
		this.loginLocal = localStorage.getItem('is_logegin', false);

            },
       }
   </script>