<template>

<section class="popular-services truly-one-service dots-hover">
        <div class="container">
            <div class="section-heading text-center">
                <h2>Most Popular Services</h2>
                <span>What do you need to find?</span>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="service-carousel">
                        <div class="service-sliderhome2 slider aos" data-aos="fade-up" v-for="item in users_subspecialization" :key="item.id">
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" :src="$store.state.apiImage+item.image">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img :src="$store.state.apiImage+item.specializationinfo.image" alt="">
                                            </router-link>	
                                            <span class="service-price">$25</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">{{item.sub_specialization}}</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">Toughened Glass Fitting Service</router-link>
                                    </h3>
                                    <div class="rating">	
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>		
                                    </div>
                                    <div class="user-info">
                                        <div class="row">	
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                <span>28-62-76-32</span>
                                            </span>
                                            <span class="col ser-location">
                                                <span>Wayne, New Jersey</span> <i class="fas fa-map-marker-alt ms-1"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
	export default {
	mounted() {
        if($('.service-sliderhome2').length > 0) {
		$('.service-sliderhome2').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}
	},
    methods:{
        getsubSpecializationMasterList() {
        axios
            .get(this.$store.state.apiEndpoint + "api/getSubSpecializationlist")
            .then(response => {
            this.users_subspecialization = response.data;
            console.log(response);
        })
            .catch(error => {
            console.log(error);
            alert("auth error");
        });
    },
    },
	mounted() {
	this.getsubSpecializationMasterList();
	},
    data() {
        return {
            users_subspecialization: []
        }
    }
	}
	</script>