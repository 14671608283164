<template>

	<div class="main-wrapper">

	<!-- <layout-headercustomer></layout-headercustomer> -->
    <layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">Payment History</h4>
                    <div class="card transaction-table mb-0">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Issue Title</th>
                                            <th>Service</th>
                                            <th>Technicain</th>
                                            <th>Address-on</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in users_requestpaylist" :key="item.id">
                                            <td>{{item.enquiry.issue_title}}</td>
                                            <td>
                                                <a href="javascript:void(0);">
                                                    <img :src="$store.state.apiImage+item.enquiry.subspecializationinfo.image" class="pro-avatar" alt=""> 
                                                    {{item.enquiry.specializationinfo.specialization}}-{{item.enquiry.subspecializationinfo.sub_specialization}}
                                                </a>
                                                <!-- {{item.enquiry_id}} -->
                                            </td>
                                            <td>
                                                <img class="avatar-xs rounded-circle" :src="$store.state.apiImage+item.enquiry.main_technician.userinfo.userprofileimage" alt=""> 
                                                {{item.enquiry.main_technician.userinfo.name}}
                                                <!-- {{item.enquiry_id}} -->
                                            </td>
                                            <td>{{item.enquiry.address_on}}</td>
                                            <td><strong>{{item.amount}}</strong></td>
                                            <td><span class="badge bg-danger-light">{{status[item.status]}}</span></td>
                                            <td><span class="badge bg-info-light" v-if="item.status==1" @click="goToReg1(item)">Pay</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <advancepaymentcustdetails></advancepaymentcustdetails> -->
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>
<script>
import axios from "axios";
	import userpayment from '../../../../assets/json/website/userpayment.json'
	const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/img/provider', false, /\.png$|\.jpg$/)
	export default {
        data() {
        return {
            userpayment: userpayment,
            users_requestpaylist:[],
      status:[
          "New",
          "Requested",
          "Payment initiated",
          "Payment processing",
          "Payment success",
          "Payment failed",
          "Payment re-initiated"
      ],
     }
    },
    
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
        goToReg1(tr){
            window.open('https://payments.skilledone.ca/#/Paymentdetails/'+tr.id, '_blank');;
        },
        getListPayment(){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
        axios
                .get(this.$store.state.apiEndpoint +"api/enquiryCustPaymentRequestGet/",auth)
                .then(response => {
                  this.users_requestpaylist=response.data;
                  this.users_requestpaylist.reverse();
                  console.log(response,"sdfsd");
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });
        },
    },
	mounted() {
        this.getListPayment();

    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	}
	</script>