<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Copyright</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="Copyright" class="check" type="checkbox" checked="">
                                                        <label for="Copyright" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Copyright</label>
                                                <!--<textarea class="form-control summernote"></textarea>-->
                                                <div id="editor"></div>
                                            </div>
                                            <div class="form-group">
                                                <h6 class="form-heads mb-0">Links</h6>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-head mb-0">Footer bottom links<span>( Max 3 only )</span></label>
                                            </div>
                                            <div class="settingset">
                                                <div class="form-group links-conts">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Terms and Condition">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/search/terms-condition">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-conts">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Privacy">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/search/privacy">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-conts">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" placeholder="Label">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" placeholder="Link with http:// Or https://">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <a class="btn  btn-success addnew"><i class="fa fa-plus me-2"></i>Add New</a>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update">Save</a>
                                            </div>
                                        </div>
                                    </div>
</template>