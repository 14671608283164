<template>
<div class="col-xl-4 col-md-6 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label>Customer Name</label>
                                                    <div class="multipleSelection">
                                                        <div class="selectBox">
                                                            <p class="mb-0">Select Customer</p>
                                                            <span class="down-icon"><i class="fas fa-chevron-down"></i></span>
                                                        </div>						  
                                                        <div id="checkBoxes-one">
                                                            <p class="checkbox-title">Customer Search</p>
                                                            <div class="form-custom">
                                                                <input type="text" class="form-control bg-grey" placeholder="Enter Customer Name">
                                                            </div>
                                                            <div class="selectBox-cont">
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span>  Brian Johnson
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span>  Russell Copeland
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span>  Greg Lynch
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span> John Blair
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span> Barbara Moore
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span> Hendry Evan
                                                                </label>
                                                                <label class="custom_check w-100">
                                                                    <input type="checkbox" name="username">
                                                                    <span class="checkmark"></span> Richard Miles
                                                                </label>
                                                            </div>
                                                            <button type="submit" class="btn w-100 btn-primary">Apply</button>
                                                            <button type="reset" class="btn w-100 btn-grey">Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Po Number</label>
                                                    <input class="form-control" type="text" placeholder="Enter Reference Number">
                                                </div>
                                            </div>
</template>