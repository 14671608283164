<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Services</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                   <servicefilter></servicefilter>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="servicelist">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Service</th>
                                                    <th>Location</th>
                                                    <th>Category</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in servicelist" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" :src="loadImg(item.img)" alt=""> {{item.title}}
                                                        </router-link>
                                                    </td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.name1}}</td>
                                                    <td>{{item.rate}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_1" class="check" type="checkbox">
                                                            <label for="service_1" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import servicelist from '../../../assets/json/admin/service/servicelist.json'
    import util from '../../../assets/utils/util'
	const images = require.context('../../../assets/admin_img/services', false, /\.png$|\.jpg$/)
    import Vue from 'vue'
    export default {
      mounted() {
        util.datatable('#servicelist')
      },
      methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
      },
      data() {
        return {
            servicelist: servicelist
        }
    }
    }
  </Script>