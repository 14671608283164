<template>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
    <!-- Breadcrumb -->
	<breadcrumb2></breadcrumb2>
	<!-- /Breadcrumb -->
		
		<div class="content" style="min-height: 299.1px;">
			<div class="container">
				<div class="catsec clearfix">
					<div class="row">
						<div class="col-lg-4 col-md-6" v-for="item in users_specialization" :key="item.id">
							<router-link :to='"/Subcats/"+item.id'>
								<div class="cate-widget">
									<img :src="$store.state.apiImage+item.image" alt="">
									<div class="cate-title">
										<h3><span><i class="fas fa-circle"></i> {{item.specialization}}</span></h3>
									</div>
									<!-- <div class="cate-count">
										<i class="fas fa-clone"></i> {{item.id}}
									</div> -->
								</div>
							</router-link>
						</div>
					</div>
					<!-- <div class="pagination">
						<ul>
							<li class="active"><a href="javascript:void(0);">1</a></li>
							<li class="ms-1"><a href="javascript:void(0);">2</a></li>
							<li class="ms-1"><a href="javascript:void(0);">3</a></li>
							<li class="ms-1"><a href="javascript:void(0);">4</a></li>
							<li class="arrow"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
						</ul>
					</div> -->
				</div>
			</div>﻿ 
		</div>
	
	<layout-footer></layout-footer>

	</div>

</template>
<script>
import axios from 'axios';
	import categories from '../../../assets/json/website/categories.json'
	const images = require.context('../../../assets/img/category', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
		this.getSpecializationMasterList();
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
		getSpecializationMasterList() {
		axios
			.get(this.$store.state.apiEndpoint + "api/SpecializationMasterList")
			.then(response => {
			this.users_specialization = response.data;
			console.log(response);
		})
			.catch(error => {
			console.log(error);
			alert("auth error");
		});
	},
    },
	data() {
        return {
			users_specialization: [],
            categories: categories
        }
    }
	}
	</script>