<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                   <adminindexwidget></adminindexwidget>

                    <div class="row">

                        <adminrecentbooking></adminrecentbooking>

                        <adminpayment></adminpayment>

                    </div> 
                </div>
            </div> 
		</diV>
	</div>
</template>