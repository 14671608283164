<template>
<div class="col-xl-3 col-md-4 theiaStickySidebar">
    <div class="stickyside">
                    <div class="mb-4">
                        <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                            <img alt="profile image" src="../../../../assets/img/provider/provider-01.jpg" class="avatar-lg rounded-circle">
                            <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                                <h6 class="mb-0">Thomas Herzberg</h6>
                                <p class="text-muted mb-0">Member Since Apr 2020</p>
                            </div>
                        </div>
                    </div>
                    <div class="widget settings-menu">
                        <ul>
                            <li class="nav-item">
                                <router-link to="/provider-dashboard" class="nav-link">
                                    <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/my-services" class="nav-link">
                                    <i class="far fa-address-book"></i> <span>My Services</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-bookings" class="nav-link">
                                    <i class="far fa-calendar-check"></i> <span>Booking List</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-settings" class="nav-link">
                                    <i class="far fa-user"></i> <span>Profile Settings</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-wallet" class="nav-link">
                                    <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-subscription" class="nav-link">
                                    <i class="far fa-calendar-alt"></i> <span>Subscription</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-availability" class="nav-link">
                                    <i class="far fa-clock"></i> <span>Availability</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-reviews" class="nav-link">
                                    <i class="far fa-star"></i> <span>Reviews</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/provider-payment" class="nav-link">
                                    <i class="fas fa-hashtag"></i> <span>Payment</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
    </div>
</template>