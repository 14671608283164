<template>
 <div class="card mb-0">
                        <div class="row no-gutters">
                            <div class="col-lg-8">
                                <div class="card-body">
                                    <h6 class="title">Plan Details</h6>
                                    <div class="sp-plan-name">
                                        <h6 class="title">
                                            Gold <span class="badge badge-success badge-pill">Active</span>
                                        </h6>
                                        <p>Subscription ID: <span class="text-base">100394949</span></p>
                                    </div>
                                    <ul class="row">
                                        <li class="col-6 col-lg-6">
                                            <p>Started On 15 Jul, 2020</p>
                                        </li>
                                        <li class="col-6 col-lg-6">
                                            <p>Price $1502.00</p>
                                        </li>
                                    </ul>
                                    <h6 class="title">Last Payment</h6>
                                    <ul class="row">
                                        <li class="col-sm-6">
                                            <p>Paid at 15 Jul 2020</p>
                                        </li>
                                        <li class="col-sm-6">
                                            <p><span class="text-success">Paid</span>  <span class="amount">$1502.00</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="sp-plan-action card-body">
                                    <div class="mb-2">
                                        <router-link to="/provider-subscription" class="btn btn-primary"><span>Change Plan</span></router-link>
                                    </div>
                                    <div class="next-billing">
                                        <p>Next Billing on <span>15 Jul, 2021</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</template>