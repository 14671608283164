<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Wallet</h4>
                                    <div class="wallet-details">
                                        <span>Wallet Balance</span>
                                        <h3>$3885</h3>
                                        <div class="d-flex justify-content-between my-4">
                                            <div>
                                                <p class="mb-1">Total Credit</p>
                                                <h4>$5314</h4>
                                            </div>
                                            <div>
                                                <p class="mb-1">Total Debit</p>
                                                <h4>$1431</h4>
                                            </div>
                                        </div>
                                        <div class="wallet-progress-chart">
                                            <div class="d-flex justify-content-between">
                                                <span>$1431</span>
                                                <span>$5,314.00</span>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <withdraw></withdraw>
                    </div>
                    
                    <h4 class="mb-4">Recent Transactions</h4>
                    <wallettable></wallettable>
                </div>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>