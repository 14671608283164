<template>
<div class="col-md-6">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">Twiter Login Credential</h5>
                                    <div class="status-toggle d-flex justify-content-between align-items-center">
                                        <input type="checkbox" id="status_3" class="check">
                                        <label for="status_3" class="checktoggle">checkbox</label>
                                    </div>
                                </div>
                                <div class="card-body pt-0">
                                    <form>
                                        <div class="settings-form">
                                            <div class="form-group form-placeholder">
                                                <label>Client ID <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Client Secret <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="settings-btns">
                                                    <button type="submit" class="btn btn-orange">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
</template>