<template>
<div class="invoice-item invoice-item-one">
									<div class="row">
										<div class="col-md-6">
											<div class="invoice-logo">
												<img src="../../../../assets/admin_img/logo.png" alt="logo">
											</div>
											<div class="invoice-head">
												<h2>Invoice</h2>
												<p>Invoice Number : In983248782</p>
											</div>
										</div>
										<div class="col-md-6">
											<div class="invoice-info">
												<strong class="customer-text-one">Invoice From</strong>
												<h6 class="invoice-name">Company Name</h6>
												<p class="invoice-details">
													9087484288 <br>
													Address line 1, Address line 2<br>
													Zip code ,City - Country
												</p>
											</div>
										</div>
									</div>
								</div>
                                <!-- Invoice Item -->
								<div class="invoice-item invoice-item-two">
									<div class="row">
										<div class="col-md-6">
											<div class="invoice-info">
												<strong class="customer-text-one">Billed to</strong>
												<h6 class="invoice-name">Customer Name</h6>
												<p class="invoice-details invoice-details-two">
													9087484288 <br>
													Address line 1, <br>
													Address line 2 <br>
													Zip code ,City - Country
												</p>
											</div>
										</div>
										<div class="col-md-6">
											<div class="invoice-info invoice-info2">
												<strong class="customer-text-one">Payment Details</strong>
												<p class="invoice-details">
													Debit Card <br>
													XXXXXXXXXXXX-2541 <br>
													HDFC Bank
												</p>
												<div class="invoice-item-box">
													<p>Recurring : 15 Months</p>
													<p class="mb-0">PO Number : 54515454</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- /Invoice Item -->
</template>