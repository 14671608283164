<template>

	<div class="main-wrapper">

	<!-- <layout-headercustomer></layout-headercustomer> -->
    <layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row">
                        <div class="col-lg-4">
                            <router-link to="/user-bookings" class="dash-widget dash-bg-1">
                                <span class="dash-widget-icon">223</span>
                                <div class="dash-widget-info">
                                    <span>Bookings</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/user-reviews" class="dash-widget dash-bg-2">
                                <span class="dash-widget-icon">16</span>
                                <div class="dash-widget-info">
                                    <span>Reviews</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/notifications" class="dash-widget dash-bg-3">
                                <span class="dash-widget-icon">1</span>
                                <div class="dash-widget-info">
                                    <span>Notification</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>