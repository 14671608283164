<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title">Settings</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <settingsidebar></settingsidebar>
                    
                    <div class="row">
                        
                        <paymentcontent></paymentcontent>

                        <paymentcontent1></paymentcontent1>

                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      components: {
     
      },
      mounted() {
      },
    }
  </Script>