<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Featured Categories</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="featured" class="check" type="checkbox" checked="">
                                                        <label for="featured" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label>Content</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-head mb-0">Categories<span>( Max 6 only )</span></label>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Construction</option>
                                                                <option>Car Wash</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Car Wash</option>
                                                                <option>Electrical</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Electrical</option>
                                                                <option>Electrical</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Cleaning</option>
                                                                <option>Electrical</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Interior</option>
                                                                <option>Electrical</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-11 col-12">
                                                            <select class="form-control select">
                                                                <option>Select Category</option>
                                                                <option>Electrical</option>
                                                                <option>Interior</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <a class="btn  btn-success"><i class="fa fa-plus me-2"></i>Add New</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-groupbtn">
                                                    <a class="btn btn-update me-2">Update</a>
                                                    <a class="btn btn-cancel">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
</template>