<template>
    <div class="card">
        <div class="card-header">
            <div class="card-heads">
                <h4 class="card-title">Banner Settings</h4>
                <div class="col-auto">
                    <div class="status-toggle">
                        <input id="banner" class="check" type="checkbox" checked="">
                        <label for="banner" class="checktoggle">checkbox</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" >
                </div>
                <div class="form-group">
                    <label>Content</label>
                    <input type="text" class="form-control" >
                </div>
                <div class="form-group">
                    <p class="settings-label">Banner image</p>
                    <div class="settings-btn">
                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                        <label for="file" class="upload">
                            <i class="fa fa-upload"></i>
                        </label>
                    </div>
                    <div class="upload-images d-block">
                        <img src="../../../../assets/admin_img/services/service-13.png" alt="Image">
                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                            <i class="fa fa-times"></i>
                        </a>
                        <h6>banner-2.jpg</h6>
                        <h5>43 KB</h5>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-5 col-12">
                            <div class="card-heads mb-3">
                                <h4 class="card-title f-14">Main Seacrch </h4>
                                <div class="col-auto">
                                    <div class="status-toggle">
                                        <input id="default_o" class="check" type="checkbox" checked="">
                                        <label for="default_o" class="checktoggle">checkbox</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-heads mb-3">
                                <h4 class="card-title f-14">Popular Searches </h4>
                                <div class="col-auto">
                                    <div class="status-toggle">
                                        <input id="default" class="check" type="checkbox" checked="">
                                        <label for="default" class="checktoggle">checkbox</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Popular Searches Label Name</label>
                    <input type="text" class="form-control" >
                </div>
                <div class="form-groupbtn">
                    <a class="btn btn-update me-2">Update</a>
                    <a class="btn btn-cancel">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>