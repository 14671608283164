<template>

    <div class="body-four">

    <!-- Body Backgound -->
	<div class="body-img">
		<img src="../../../../assets/img/banner-three.png"  alt="">
	</div>
	<!-- /Body Backgound -->

	<div class="main-wrapper">

	<layout-header4></layout-header4>
    
	<!-- Hero Section -->
    <section class="hero-section hero-four">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 ">
                    <div class="section-search-box d-flex align-items-center aos" data-aos="fade-up">
                        <div class="section-search">
                            <h3>World's Largest <br>Marketplace</h3>
                            <p>Search From 150 Awesome Verified Ads!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 ">
                    <div class="object-img text-center aos" data-aos="fade-up">
                        <img src="../../../../assets/img/object-1.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Hero Section -->
    
    <!-- home Search -->
    <section class="popular-search electrical-search">
        <div class="true-search-box">
            <div class="container">
                <div class="true-search-fix aos" data-aos="fade-up">
                    <div class="search-box ">
                        <form> 
                            <div class="search-input line">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="What are you looking for?">
                                </div>
                            </div>
                            <div class="search-input">
                                <i class="fas fa-location-arrow bficon"></i>
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="Your Location"> 
                                </div>
                            </div>
                            <router-link to="/search">
                            <div class="search-btn">
                                <button class="btn search_service">Search</button>
                            </div>
                        </router-link>
                        </form>
                    </div>
                    <div class="search-cat">
                        <i class="fas fa-circle"></i>
                        <span>Popular Searches</span>
                        <router-link to="/search">Electrical  Works</router-link>
                        <router-link to="/search">Cleaning</router-link>
                        <router-link to="/search">AC Repair</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /home Search -->
    
    <!-- Featured Category  -->
    <feature2></feature2>
    <!-- Featured Category -->
    
    <!-- Popular Servides -->
    <popular2></popular2>
    <!-- /Popular Servides -->
    
    <!-- How It Works -->
    <work2></work2>
    <!-- /How It Works -->

    <!-- /our app -->
    <app2></app2>
    <!-- /our app -->

	<layout-footer4></layout-footer4>

	</div>

    </div>

</template>
<script>
	export default {
		mounted() {
		},
	}
</script>