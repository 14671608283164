<template>
<!-- Footer -->
		<footer class="footer footer-three">
		
			<!-- Footer Top -->
			<div class="footer-top aos" data-aos="fade-up">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<div class="footer-logo-sell">
									<img src="../../../../assets/img/logo-two.png" alt="img">
								</div>
								<div class="footer-about">
									<p>Nunc placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scel erisque the mattis, </p>
								</div>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Service</h2>
								<ul>
									<li>
										<router-link to="/search"><i class="fas fa-check-double"></i>Hire Guide</router-link>
									</li>
									<li>
										<router-link to="/search"><i class="fas fa-check-double"></i>Hotel Book</router-link>
									</li>
									<li>
										<router-link to="/search"><i class="fas fa-check-double"></i>Car Rent</router-link>
									</li>
									<li>
										<router-link to="/search"><i class="fas fa-check-double"></i>Bike Rent</router-link>
									</li>
									<li>
										<router-link to="/search"><i class="fas fa-check-double"></i>Bus Ticket</router-link>
									</li>
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-3 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-contact">
								<h2 class="footer-title">Contact </h2>
								<div class="footer-contact-info">
									<div class="footer-address">
										<span><i class="fa fa-map-marker"></i></span>
										<p>367 Hillcrest Lane, Irvine, California, United States</p>
									</div>
									<p><i class="fa fa-mobile"></i> 321 546 8764</p>
									<p class="mb-0"><i class="fas fa-envelope"></i> truelysell@example.com</p>
								</div>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="footer-dots">
								<img src="../../../../assets/img/foot-dot.png" alt="img">
							</div>
							<!-- Footer Widget -->
							<div class="footer-widget">
								<h2 class="footer-title">Follow Us</h2>
								<div class="subscribe-form">
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                    <router-link to="/search" class="btn footer-btn d-flex align-items-center justify-content-center">
                                        <i class="fas fa-paper-plane"></i>
                                    </router-link>
                                </div>
								<div class="social-icon">
									<ul>
										<li>
											<a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook-f"></i> </a>
										</li>
										<li>
											<a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i> </a>
										</li>
										<li>
											<a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a>
										</li>
										<li>
											<a href="javascript:void(0);" target="_blank"><i class="fab fa-google"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<!-- /Footer Widget -->
						</div>
					</div>
				</div>
			</div>
			<!-- /Footer Top -->
			
			<!-- Footer Bottom -->
			<div class="footer-bottom">
				<div class="container">
					<!-- Copyright -->
					<div class="copyright">
						<div class="row">
							<div class="col-md-6 col-lg-6">
								<div class="copyright-text">
									<p class="mb-0">&copy; 2022 <router-link to="/">Truelysell</router-link>. All rights reserved.</p>
								</div>
							</div>
							<div class="col-md-6 col-lg-6">
								<!-- Copyright Menu -->
								<div class="copyright-menu">
									<ul class="policy-menu">
										<li>
											<router-link to="/term-condition">Terms and Conditions</router-link>
										</li>
										<li>
											<router-link to="/privacy-policy1">Privacy</router-link>
										</li>
									</ul>
								</div>
								<!-- /Copyright Menu -->
							</div>
						</div>
					</div>
					<!-- /Copyright -->
				</div>
			</div>
			<!-- /Footer Bottom -->
			
		</footer>
		<!-- /Footer -->
</template>