<template>
<!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Sub Categories</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn me-3" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                                <router-link to="/admin/add-subcategory" class="btn btn-primary add-button ml-3">
                                    <i class="fas fa-plus"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                        <!-- Search Filter -->
                        <div class="card filter-card" id="filter_inputs">
                            <div class="card-body pb-0">
                                <form action="#" method="post">
                                    <div class="row filter-row">
                                        <div class="col-sm-6 col-md-3">
                                            <div class="form-group">
                                                <label>Sub Category</label>
                                                <input class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="form-group">
                                                <label>From Date</label>
                                                <div class="cal-icon">
                                                    <datepicker v-model="startdate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="form-group">
                                                <label>To Date</label>
                                                <div class="cal-icon">
                                                    <datepicker v-model="enddate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="form-group">
                                                <button class="btn btn-primary btn-block w-100" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    <!-- /Search Filter -->
</template>
<script>
    import { ref } from 'vue'
    const currentDate = ref(new Date())
    const currentDate1 = ref(new Date())
	export default {
	mounted() {
	},
    data() {
        return {
            startdate: currentDate,
            enddate: currentDate1
        }
    }
	}
	</script>
