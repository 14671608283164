<template>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
    <!-- Breadcrumb -->
		<breadcrumb5></breadcrumb5>
		<!-- /Breadcrumb -->
		
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<searchsidebar></searchsidebar>
					<div class="col-lg-9">
						<div class="row align-items-center mb-4">
							<div class="col-md-6 col">
								<h4><span>Our</span> Services</h4>
							</div>
							<div class="col-md-6 col-auto">
								<div class="view-icons">
									<!-- <a href="javascript:void(0);" class="grid-view active"><i class="fas fa-th-large"></i></a> -->
								</div>
							</div>
						</div>
							<searchtable></searchtable>
					</div>
				</div>
			</div>
		</div>
	
	<layout-footer></layout-footer>

	</div>

</template>


<script>
import axios from "axios";
export default {
  components: {
  },
  data() {
    return {
      isSaved: false,
      createdata: {}
    };
  },

  methods: {
    getData: function() {},
    
  },
  mounted() {
	// alert("t1")
;  }
};
</script>