<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Header Menu</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="menu" class="check" type="checkbox" checked="">
                                                        <label for="menu" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="card-heads">
                                                    <h4 class="card-title f-14">Nav Menus</h4>
                                                    <div class="col-auto">
                                                        <a class="btn btn-reset">Reset</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="settings-form">
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Categories">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/all-categories">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="About Us">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/about-us">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont" >
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" value="Contact Us">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" value="https://truelysell.com/contact">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group links-cont">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 col-12">
                                                            <input type="text" class="form-control" placeholder="Label">
                                                        </div>
                                                        <div class="col-lg-8 col-12">
                                                            <input type="text" class="form-control" placeholder="Link with http:// Or https://">
                                                        </div>
                                                        <div class="col-lg-1 col-12">
                                                            <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                                <i class="far fa-trash-alt "></i> 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <a class="btn  btn-success addlinks"><i class="fa fa-plus me-2"></i>Add New</a>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
</template>