<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">Dashboard</h4>
                    <div class="row">
                        <div class="col-lg-4">
                            <router-link to="/provider-bookings" class="dash-widget dash-bg-1">
                                <span class="dash-widget-icon">245</span>
                                <div class="dash-widget-info">
                                    <span>Bookings</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/my-services" class="dash-widget dash-bg-2">
                                <span class="dash-widget-icon">66</span>
                                <div class="dash-widget-info">
                                    <span>Services</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/notifications" class="dash-widget dash-bg-3">
                                <span class="dash-widget-icon">8</span>
                                <div class="dash-widget-info">
                                    <span>Notification</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <providerdash1></providerdash1>
                </div>
            </div>
        </div>﻿
        </div>
	
	<layout-footer></layout-footer>

	</div>

</template>