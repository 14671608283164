<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title">Settings</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                   
                    <settingsidebar></settingsidebar>
                    
                    <div class="row">
                        <socialcontent></socialcontent>
                        <socialcontent1></socialcontent1>
                        <socialcontent2></socialcontent2>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      data() {
      return {
        password: ["Select Mail Encryption", "Mail Encryption", "Encryption"]
      }
      },
      components: {
     
      },
      mounted() {
      },
    }
  </Script>