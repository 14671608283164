<template>
    <section class="how-work truely-one-work">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading text-center aos" data-aos="fade-up">
                        <h2>How It Works</h2>
                        <span>What do you need to find?</span>
                    </div>
                    <div class="howworksec">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork aos" data-aos="fade-up">
                                    <div class=" d-flex justify-content-center">
                                        <div class="list-out d-flex align-items-center"><span>1</span></div>
                                        <div class="iconround">
                                            <img src="../../../../assets/img/work-icon-1.svg" alt="">
                                        </div>
                                    </div>
                                    <h3>Choose What To Do</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork aos" data-aos="fade-up">
                                    <div class=" d-flex justify-content-center">
                                        <div class="list-out d-flex align-items-center"><span class="list-number">2</span></div>
                                        <div class="iconround">
                                            <img src="../../../../assets/img/work-icon-2.svg" alt="">
                                        </div>
                                    </div>
                                    <h3>Find What You Want</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork aos" data-aos="fade-up">
                                    <div class=" d-flex justify-content-center">
                                        <div class="list-out d-flex align-items-center"><span class="list-number">3</span></div>
                                        <div class="iconround">
                                            <img src="../../../../assets/img/work-icon-3.png" alt="">
                                        </div>
                                    </div>
                                    <h3>Amazing Places</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>