<template>
     <div class="row align-items-center mb-4">
                        <div class="col-xl-9 col-md-8">
                            <h4 class="widget-title">My Bookings</h4>
                            <div class="card transaction-table mb-0">
                                <div class="card-body">
                                    <div class="table-responsive">
                                <table class="table mb-0">
                                <!-- <select class="form-control-sm custom-select"> -->
                                <!-- <select class="form-control form-control selectbox select form-select" v-model="catid">
                                <option>All</option>
                                    
								<option v-for="item in enq_status" v-bind:key="item.id" v-bind:value="item">{{item}}</option> -->
                                    <!-- <option>Pending</option>
                                    <option>Inprogress</option>
                                    <option>Complete Request</option>
                                    <option>Rejected</option>
                                    <option>Cancelled</option>
                                    <option>Completed</option> -->
                                <!-- </select>  -->
                                <!-- {{catid}}-1 -->

                                    <thead>
                                        <tr>
                                            <th>Issue Title</th>
                                            <th>Description</th>
                                            <th>address_on</th>
                                            <th>Enq Status</th>
                                            <th>Process Status</th>
                                        </tr>
                                    </thead>
                                    <tr v-for="item in enruiryDetialsbyid" :key="item.id">
                                        <td> {{item.issue_title }}</td>
                                        <td> {{item.address_on }}</td>
                                        <td> {{ item.enq_status }}</td>
                                        <td> {{ item.process_status }}</td>
                                    </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                  
                        <!-- end -->
    <div class="bookings" v-for="item in users_enquiry" :key="item.id">
        <div class="booking-list">
            <div class="booking-widget">
                <router-link to="/service-details" class="booking-img">
                    <img :src="$store.state.apiImage+item.subspecializationinfo.image" alt="User Image">
                </router-link>
                <div class="booking-det-info">
                    <h3>
                        <router-link to="/service-details">{{item.issue_title}}</router-link>
                    </h3>
                    <!-- {{item.description}} -->
                    <ul class="booking-details">
                        <li>
                            <span>Date</span> {{item.address_on}} <span class="badge badge-pill badge-prof bg-primary">{{getStatus(item.process_status)}}</span>
                        </li>
                        <!-- <li><span>Enquiry status</span> {{item.enq_status}}</li> -->
                        <li><span>Job Address</span> 
                                {{item.address}},
                                {{item.cityinfo.city}},
                                {{item.stateinfo.state}},
                                {{item.countryinfo.country}}</li>
                        <li><span>job contact</span> {{item.contact_number}}</li>
                        <li>
                            <span>Assigned Technician</span>
                            <div class="avatar avatar-xs me-1">
                                <img class="avatar-img rounded-circle" alt="User Image" :src="($store.state.apiImage+item.main_technician.userinfo.userprofileimage)">
                            </div>
                            {{item.main_technician!=null?item.main_technician.userinfo.name:''}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="booking-action">
                <a class="btn btn-sm bg-info-light" data-bs-toggle="modal" data-bs-target="#enquiryDetails_modal" @click="checkEnquiryDetails (item)">
                <i class="far fa-eye"></i> Details</a>
                <!-- <a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> cancel</a> -->
            </div>
        </div>
    </div>
    <!-- Enquiry Details Modal -->
    <div class="modal account-modal fade" id="enquiryDetails_modal" >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header p-0 border-0">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
                    </button>
                    <br>
                </div><h3 align="center" style="color:brown;">Enquiry Details</h3>
                <!-- <br>{{enruiryDetialsbyid.subspecializationinfo}} -->
                <table >
                    <tbody align="center">
                        <tr><td style="color:blue" align="right">Issue details</td></tr>

                        <tr><td style="width:30%" align="right" >Enquiry id</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{enruiryDetialsbyid.id}}</td></tr>

                        <tr><td style="width:30%" align="right" >Issue title</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{enruiryDetialsbyid.issue_title}}</td></tr>

                        <tr><td style="width:30%" align="right" >description</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{enruiryDetialsbyid.description}}</td></tr>

                        <tr><td style="width:30%" align="right" >Services </td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">
                            <!-- {{enruiryDetialsbyid.specialization}} - {{enruiryDetialsbyid.sub_specialization}}</td></tr> -->
                            {{enruiryDetialsbyid.specialization!=null?enruiryDetialsbyid.specializationinfo.specialization:''}} - {{enruiryDetialsbyid.specialization!=null?enruiryDetialsbyid.subspecializationinfo.sub_specialization:''}}</td></tr>

                        <tr><td style="width:30%" align="right" >address_on</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{enruiryDetialsbyid.address_on}}</td></tr>

                            <tr><td style="width:30%" align="right" >Time</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{(timestatus[enruiryDetialsbyid.time])}}</td></tr>

                            <!-- (timestatus[enruiryDetialsbyid.time]) -->

                            <tr><td style="color:blue" colspan="2" align="center">Contact & Communication details</td></tr>

                        <tr><td style="width:30%" align="right" >contact_number</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{enruiryDetialsbyid.contact_number}}</td></tr>

                        <tr><td style="width:30%" align="right" >address</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">
                                {{enruiryDetialsbyid.address}},
                                {{enruiryDetialsbyid.city!=null?enruiryDetialsbyid.cityinfo.city:''}}, 
                                {{enruiryDetialsbyid.state!=null?enruiryDetialsbyid.stateinfo.state:''}},  
                                {{enruiryDetialsbyid.country!=null?enruiryDetialsbyid.countryinfo.country:''}}.
                            </td></tr>

                        <br>

                        <tr><td style="width:30%" align="right" >process_status</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">{{getStatus(enruiryDetialsbyid.process_status)}}</td></tr>

                        <tr><td style="width:30%" align="right" >enq_status</td><td style="width:10%">:</td>
                            <td style="width:60%;color:rebeccapurple;" align="left">
                                {{(enq_status[enruiryDetialsbyid.enq_status]!=null?enq_status[enruiryDetialsbyid.enq_status]:'not yet')}}</td></tr>
                            
                        
                    </tbody>
                </table>
                
                <!-- {{enruiryDetialsbyid}} -->
                <!-- <div class="modal-body">
                    <div class="login-header">
                        <h3>Login <span>Truelysell</span></h3>
                    </div>
            
                        <div class="form-group form-focus">
                            <label class="focus-label">Email</label>
                            <input type="email" class="form-control" placeholder="example@example.com" >
                        </div>
                        <div class="form-group form-focus">
                            <label class="focus-label">Password</label>
                            <input type="password" class="form-control" placeholder="********">
                        </div>
                        <div class="text-end">  
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-primary btn-block btn-lg login-btn" data-bs-dismiss="modal" >Login</button>
                        </div>
                        <div class="text-center dont-have">Don’t have an account? <a data-bs-toggle="modal" data-bs-target="#user-register">Register</a>
                        </div>
                    
                </div> -->
                <br><br>
            </div>
        </div>
    </div>
    <!-- /Enquiry Details Modal -->

</template>
<script>
import axios from 'axios';
	import bookinglist from '../../../../assets/json/website/bookinglist.json'
	const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/img/provider', false, /\.png$|\.jpg$/)
	export default {
        data() {
        return {
            catid:'',
            users_enquiry:[],
            enruiryDetialsbyid:[],
            enq_status:[
                    "New Enquiry",
                    "Addressing Issue",
                    "Estimating",
                    "Negosiating for prize",
                    "Order Conformed",
                    "Work In Progress",
                    "Work Done"
                ],
                timestatus:[
                    "00:00 to 01:00",
                    "01:00 to 02:00",
                    "02:00 to 03:00",
                    "03:00 to 04:00",
                    "04:00 to 05:00",
                    "05:00 to 06:00",
                    "06:00 to 07:00",
                    "07:00 to 08:00",
                    "08:00 to 09:00",
                    "09:00 to 10:00",
                    "10:00 to 11:00",
                    "11:00 to 12:00",
                    "12:00 to 13:00",
                    "13:00 to 14:00",
                    "14:00 to 15:00",
                    "15:00 to 16:00",
                    "16:00 to 17:00",
                    "17:00 to 18:00",
                    "18:00 to 19:00",
                    "19:00 to 20:00",
                    "20:00 to 21:00",
                    "21:00 to 22:00",
                    "22:00 to 23:00",
                    "23:00 to 00:00"
                ],
            bookinglist: bookinglist
        }
    },
    methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
        getStatus(key){
  switch(key){
  case 0:
  return "New Orders";
  case 1:
  return "Saved Orders";
  case 2:
  return "Approved";
  case 3:
  return "Rejected"; 
   }
},

getStatusOrder(key){
  switch(key){
  case 1:
  return "Service Walkin";
  case 2:
  return "Contract Based Service";
  case 3:
  return "Refixing Past Service";
   }
},
getStatusSpecializationtype(key){
  switch(key){
  case 1:
  return "Any";
  case 2:
  return "Choose From Past";
  case 3:
  return "Chhose Myself";
   }
},
  
         getList(){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
         axios
                 .get(this.$store.state.apiEndpoint +"api/getMyOrders",auth)
                 .then(response => {
                   this.users_enquiry=response.data.result;
               this.users_enquiry.reverse();
                   console.log(response);
                })
                 .catch(error => {
                  console.log(error);
                   alert("auth error");
                 });
         },  

         checkEnquiryDetails(item){
            this.enruiryDetialsbyid=item;
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
         axios
                 .get(this.$store.state.apiEndpoint +"api/getcheckenquiryisexist/"+item.id,auth)
                 .then(response => {
                   this.enruiryDetialsbyid=response.data.result;
                   console.log(response,"hsdjkfsh");
                })
                 .catch(error => {
                  console.log(error);
                   alert("auth error");
               });
        },


         updatebookorders(tableRow){
            const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
            this.createobj = tableRow;
            axios
            .put(this.$store.state.apiEndpoint+"api/bookorderupdate",this.createobj,auth)
                .then(response => { 
                  (response.data==true)
                  this.getList();
                  this.$vs.notify({color:'success',title:'Order Booked Successfully',text:''}) 
              
                })
                .catch(error => {
                  console.log(error);
                  alert("auth error");
                });
          },
    },
	mounted() {
        this.getList();
    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	
	}
	</script>