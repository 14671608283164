<template>
<!-- Page Header -->
				<div class="page-header">
					<div class="row">
						<div class="col">
							<h3 class="page-title">Wallet</h3>
						</div>
						<div class="col-auto text-end">
							<a class="btn btn-white filter-btn" href="javascript: void(0);" id="filter_search">
								<i class="fas fa-filter"></i>
							</a>
						</div>
					</div>
				</div>
				<!-- /Page Header -->
				
				<!-- Search Filter -->
				<div class="card filter-card" id="filter_inputs">
					<div class="card-body pb-0">
						<form>
							<div class="row filter-row"> 
								<div class="col-sm-6 col-md-3">
									<div class="form-group">
										<label>Name</label>
										<input class="form-control" type="text">
									</div>
								</div> 
								<div class="col-sm-6 col-md-3">
									<div class="form-group">
										<label>From Date</label>
										<div class="cal-icon">
											<datepicker v-model="startdate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-md-3">
									<div class="form-group">
										<label>To Date</label>
										<div class="cal-icon">
											<datepicker v-model="enddate"  class="picker" 
                                                        :editable="true"
                                                        :clearable="false" />
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-md-3">
									<div class="form-group">
										<button class="btn btn-primary btn-block w-100" type="submit">Submit</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<!-- /Search Filter -->
				
				<ul class="nav nav-tabs menu-tabs">
					<li class="nav-item" :class="{'active': currentPath === '/admin/wallet'}">
						<router-link a class="nav-link" to="/admin/wallet">Wallet Report</router-link>
					</li>
					<li class="nav-item" :class="{'active': currentPath === '/admin/wallet-history'}">
						<router-link class="nav-link" to="/admin/wallet-history">Wallet History</router-link>
					</li>
				</ul>
</template>
<script>
    import { ref } from 'vue'
    const currentDate = ref(new Date())
    const currentDate1 = ref(new Date())
    export default {
        computed: {
            currentPath() {
                return this.$route.name;
            }
        },  
    mounted() {
      },
    data() {
        return {
            startdate: currentDate,
            enddate: currentDate1,
            activeClass: 'active',
        };
                //  isactive : true
      },
    }
    </script>
    <style>
    .sidebar-menu ul li a.active {
        background-color: #ff0080;
        color: #fff;
    }
    </style>