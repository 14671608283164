<template>

	<div>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
	<hero></hero>  
		
	<category></category>
	<!-- <subspec></subspec> -->
		
	<popular></popular>

	<section class="how-work">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="heading howitworks aos" data-aos="fade-up">
						<h2>How It Works</h2>
						<!-- <span>Aliquam lorem ante, dapibus in, viverra quis</span> -->
					</div>
		
	                <work></work>
				</div>
			</div>
		</div>
	</section>

	<!-- <app></app> -->

	<layout-footer></layout-footer>
	</div>
	</div>
</template>
<script>
import subspec from './subspec.vue';
	export default {
  components: { subspec },
		mounted() {
		$('.fav-btn .fav-icon').on('click', function () {
      	$(this).toggleClass('favourite');
        });
		},
	}
</script>