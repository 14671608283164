<template>
<div class="service-view" >
                        <div class="service-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h1>{{this.sub_specializationIdinfo.sub_specialization}}</h1>
                                <!-- <div class="fav-btn fav-btn-big">
                                    <a href="javascript:void(0)" class="fav-icon with-border">
                                        <i class="fas fa-heart"></i>
                                    </a>
                                </div> -->
                            </div>
                            <!-- <address class="service-location"><i class="fas fa-location-arrow"></i> Hanover, Maryland</address>
                            <div class="rating mapgridrating1">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <span class="d-inline-block average-rating">(5)</span>
                            </div> -->
                            <!-- <div class="service-cate">
                                <router-link to="">Automobile</router-link>
                            </div> -->
                        </div>
                
                        <!-- <div class="service-images service-carousel-view slider"> -->
                                <div class="item">
                                    <img :src="$store.state.apiImage+sub_specializationIdinfo.image" alt="" 
                                            style="width:300px;height:200px;
                                            border-radius: 8px; 
                                            display: block;margin-left: auto;margin-right: auto;
                                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                                </div>
                                <!-- <div class="item">
                                    <img :src="$store.state.apiImage+sub_specializationIdinfo.image" alt="" class="img-fluid">
                                </div>
                                <div class="item">
                                    <img :src="$store.state.apiImage+sub_specializationIdinfo.image" alt="" class="img-fluid">
                                </div> -->
                        <!-- </div> -->
                        <div class="service-details mt-5">
                            <!-- <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Overview</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Services Offered</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-book-tab" data-toggle="pill" href="#pills-book" role="tab" aria-controls="pills-book" aria-selected="false">Reviews</a>
                                </li>
                            </ul> -->
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="card service-description">
                                        <div class="card-body">
                                            <h5 class="card-title" style="background-color:SlateBlue;text-align:center;color:bisque" >Service Description</h5>
                                            <!-- <p class="mb-0">{{this.sub_specializationIdinfo.specializationinfo}}</p> -->
                                            <p class="mb-0">{{this.sub_specializationIdinfo.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tab-content">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="card service-description">
                                        <div class="card-body">
                                            <h5 class="card-title">our technicians</h5>
                                            <subspec></subspec>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>
                    </div>
</template>
<script>
import axios from 'axios';
import subspec from '../../dashboard/home/subspec.vue';
	export default {
  components: { subspec },
        data() {
        return {
			sub_specializationIdinfo: []
        }
    },
    methods:{
		getsubSpecializationIDinfo() {
		axios
			.get(this.$store.state.apiEndpoint + "api/getsubspecializationidinfo/"+this.$route.params.subspecialization_id)
			.then(response => {
			this.sub_specializationIdinfo = response.data;
			console.log(response);
		})
			.catch(error => {
			console.log(error);
			alert("auth error");
		});
	},
    },
        
		mounted() {
            this.getsubSpecializationIDinfo();
        if($('.service-carousel-view').length > 0) {
        $('.service-carousel-view').slick({
            dots: true,
            autoplay:false,
            infinite: true,
            prevArrow: false,
            nextArrow: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
		},
	}
</script>