<template>
    <section class="feature-category dots-hover">
        <div class="container">
            <div class="section-heading text-center aos" data-aos="fade-up">
                <h2>Featured Categories</h2>
                <span>What do you need to find?</span>
            </div>
            <div class="service-carousel">
                <div class="categories-sliderhome2 slider aos" data-aos="fade-up">
                    <div class="categories-widget">
                        <div class="categories-img d-flex justify-content-center align-items-center">
                            <div class="categories-img-box">
                                <router-link to="/search" class="d-flex justify-content-center align-items-center"> 
                                    <i class="fas fa-car"></i>
                                </router-link>
                                <div class="category-card text-center">
                                    <h4>
                                        <router-link to="/search">Car mechanism & <br>Car Wash</router-link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="categories-widget">
                        <div class="categories-img d-flex justify-content-center align-items-center">
                            <div class="categories-img-box">
                                <router-link to="/search" class="d-flex justify-content-center align-items-center"> 
                                    <i class="fas fa-laptop"></i>
                                </router-link>
                                <div class="category-card text-center">
                                    <h4>
                                        <router-link to="/search">Computer service & <br> Spares</router-link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="categories-widget">
                        <div class="categories-img d-flex justify-content-center align-items-center">
                            <div class="categories-img-box">
                                <router-link to="/search" class="d-flex justify-content-center align-items-center"> 
                                    <i class="fas fa-building"></i>
                                </router-link>
                                <div class="category-card text-center">
                                    <h4>
                                        <router-link to="/search">Building Construction & <br>Paintings</router-link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="categories-widget">
                        <div class="categories-img d-flex justify-content-center align-items-center">
                            <div class="categories-img-box">
                                <router-link to="/search" class="d-flex justify-content-center align-items-center"> 
                                    <i class="fas fa-brush"></i>
                                </router-link>
                                <div class="category-card text-center">
                                    <h4>
                                        <router-link to="/search">Steam Car & <br> Wash</router-link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="categories-widget">
                        <div class="categories-img d-flex justify-content-center align-items-center">
                            <div class="categories-img-box">
                                <router-link to="/search" class="d-flex justify-content-center align-items-center"> 
                                    <i class="fas fa-laptop"></i>
                                </router-link>
                                <div class="category-card text-center">
                                    <h4>
                                        <router-link to="/search">Computer service & <br> Spares</router-link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
	export default {
	mounted() {
        if($('.categories-sliderhome2').length > 0) {
		$('.categories-sliderhome2').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>