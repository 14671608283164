<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="card mb-0">
                        <div class="card-body">
                            <form>
                                <div class="form-group">
                                    <p>Availability <span class="text-danger">*</span>
                                    </p>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table availability-table">
                                                    <tbody>
                                                        
                                                        <providertable></providertable>
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Thursday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- friday -->
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Friday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- saturday -->
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Saturday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- sunday -->
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Sunday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="submit-section text-end">
                                    <button class="btn btn-primary submit-btn" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
        </div>
	
	<layout-footer></layout-footer>

	</div>

</template>