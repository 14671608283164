<template>

<section class="hero-section">
    <div class="layer">
        <div class="home-banner"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="section-search aos" data-aos="fade-up">
                        <h3>World's Largest <span>Marketplace</span></h3>
                        <p>Search From 150 Awesome Verified Ads!</p>
                        <br><br>
                        <div class="search-box">
                            <form> 
                                <div class="search-input line single">
                                    <i class="fas fa-tv bficon"></i>
                                    <div class="form-group mb-0">
                                            <input type="text" class="form-control" placeholder="Which service are you looking for?" v-model="searchword">
                                    </div>
                                </div>
                                <!-- <div class="search-input">
                                    <i class="fas fa-location-arrow bficon"></i>
                                    <div class="form-group mb-0">
                                        <input type="text" class="form-control" placeholder="Your Location"> 
                                        <router-link to="/categories">
                                        <a class="current-loc-icon current_location" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a>
                                    </router-link>
                                    </div>
                                </div> -->
                                <!-- <router-link to="/search">
                                <div class="search-btn"> -->
                                <router-link :to='"/searchwords/"+searchword'>
                                <div class="search-btn">
                                    <button class="btn search_service" >Search</button>
                                </div>
                                </router-link>
                                <!-- </div>
                                </router-link> -->
                            </form>
                        </div>
                        <div class="search-cat">
                            <i class="fas fa-circle"></i>
                            <span class="ms-1">Popular Searches</span>
                            <router-link to="" @click="openSubElectrician()">Electrician</router-link>
                            <router-link to="" @click="openSubPainting()">Painting</router-link>
                            <router-link to="" @click="openSubPlumbing()">Plumbing</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Hero Section -->
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select'
	
	export default {
        components: {
'v-select': vSelect,
  },
        data() {
        return {
            searchword:""
        }
    },
	methods:{
        // openSubCat(searchword){
        // this.$router.push("/searchwords/"+searchword); 
		// },
        openSubElectrician(){
                this.$router.push({
                name: 'SubcatsPg', 
                params: { catid: 2 }
            });
		},
        openSubPainting(){
                this.$router.push({
                name: 'SubcatsPg', 
                params: { catid: 8 }
            });
		},
        openSubPlumbing(){
                this.$router.push({
                name: 'SubcatsPg', 
                params: { catid: 3 }
            });
		},
    },
	}
	</script>

