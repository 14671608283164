<template>

	<div class="main-wrapper">

	<!-- <layout-headercustomer></layout-headercustomer> -->
    <layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row"> 
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="tab-content pt-0">
                        <div class="tab-pane show active" id="user_profile_settings">
                            <div class="widget">
                                <h4 class="widget-title">Profile Settings</h4>
                                <!-- <form> -->
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <h5 class="form-title">Basic Information</h5>
                                        </div>
                                        <div class="form-group col-xl-12">
                                            <div class="media align-items-center mb-3 d-flex">
                                                <img class="user-image" :src="user_pic" alt="">
                                                <div class="media-body">
                                                    <h5 class="mb-0">{{user_name}}</h5>
                                                    <p>Max file size is 20mb</p>
                                                    <button  v-if="isEdituserpic == false" class="mr-3 mb-2" @click="onEditProfilepic">Change Profile image</button>
                                                    <input   v-if="isEdituserpic == true" type="file" id="file_7" ref="file_7" v-on:change="handleFileUpload(7)"  name="file"   />
                                                    <button v-if="isEdituserpic == true" color="primary" class="mb-2" @click="updateUserProfileImage" >Update Profile image</button> 
   <!-- @click="updateUserProfileImage" -->
                                                    <!-- <div class="jstinput">	<a href="javascript:void(0);" class="avatar-view-btn browsephoto openfile">Browse</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Name</label>
                                            <input class="form-control" type="text" v-model="user_name" readonly>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Email</label>
                                            <input class="form-control" type="email" v-model="user_email" readonly>
                                        </div>
                                        <!-- <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Country Code</label>
                                            <input class="form-control" type="text" value="91" readonly>
                                        </div> -->
                                        <!-- <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Mobile Number</label>
                                            <input class="form-control no_only" type="text" value="410-242-3850" name="mobileno" readonly required>
                                        </div> -->
                                        <!-- <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Date of join</label>
                                            <input type="text" class="form-control" autocomplete="off" name="dob" value="01-01-1970">
                                        </div> -->
                                        <!-- <div class="form-group col-xl-6">
                                            <label class="me-sm-2">Date of birth</label>
                                            <input type="text" class="form-control" autocomplete="off" name="dob" value="01-01-1970">
                                        </div> 
                                            <div class="col-xl-12">
                                            <h5 class="form-title">Address</h5>
                                        </div> -->
                                        <!-- <settingtable></settingtable> -->
                                    </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>

<script>
import axios from "axios";
export default {
  data() {
  return {
file_7:null,
    user_pic_id:"",
    user_name:"",
    user_email:"",
    user_pic:"",

user_details:[],

isEdituserpic:false,

updateuserprofileimage:{
    userprofileimage:""
  },
  
  }
},
methods:{
    
handleFileUpload(index) {
if(index == 7)
    this.file_7 = this.$refs.file_7.files[0];
},

getUserDetails(){
    const auth = {
        headers: {Authorization:'Bearer ' + localStorage.getItem('token')} 
        }
    axios
        .get(this.$store.state.apiEndpoint +"api/userget",auth)
        .then(response => {
            this.user_details=response.data;
            console.log(response);
        })
        .catch(error => {
            console.log(error);
            alert("auth error getUserDetails");
        });
    }, 

onEditProfilepic(){
  this.isEdituserpic = true;
  },


      updateUserProfileImage(){
        const auth = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization:'Bearer ' + localStorage.getItem('token')
            }}
        let formData = new FormData();
        formData.append("id", this.user_pic_id);
        formData.append("userprofileimage", this.file_7);
        // debugger
        axios
            .post(this.$store.state.apiEndpoint +"api/updateuserprofileimage", formData,auth)
            .then(response => {
            (response.data==true)
            this.user_details=response.data;
            localStorage.setItem("user_userprofileimage" , response.data.userprofileimage)                
            this.user_pic=localStorage.user_userprofileimage?this.$store.state.apiImage+localStorage.user_userprofileimage:'';
            // this.$router.go(this.$router.currentRoute);
            this.$router.go();
            this.getUserDetails();
            // this.getListskillset();
            this.isEdituserpic = false;
            this.$vs.notify({
              title: "Success",
              text: "Updated Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
            });
      })
        .catch(error => {
            console.log(error);
            // alert("auth error updateUserProfileImage");
        });
        },
   },

  mounted() {
    console.log(localStorage)
    this.user_pic_id=localStorage.user_id;
    this.user_name=localStorage.user_name;
    this.user_email=localStorage.user_email;
    this.user_pic=localStorage.user_userprofileimage?this.$store.state.apiImage+localStorage.user_userprofileimage:'';
    
    this.getUserDetails();
  } 
}
</script>
 