<template>
 <section class="popular-services dots-hover service-five">
        <div class="power-service">
            <div class="container">
                <div class="section-heading section-five power-head text-center aos" data-aos="fade-up">
                    <span>Explore the greates our services. You won’t be disappointed</span>
                    <h2>Most Popular Services</h2>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="service-carousel">
                            <div class="service-slider slider owl-theme aos" data-aos="fade-up">
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-20.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-02.jpg" alt="">
                                                <ul>
                                                    <li>Thomas Herzberg</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Computer Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$400</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>28-62-76-32</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Electra, Texas</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Cleaning</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-21.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-03.jpg" alt="">
                                                <ul>
                                                    <li>Hendry Evangline</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Cleaning Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$700</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>609-639-6458</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Wayne, New Jersey</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Electrical</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-22.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-04.jpg" alt="">
                                                <ul>
                                                    <li>Hilary Desouza</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Electrical Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$200</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>0699 149 25 07</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Hanover, Maryland</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Construction</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-21.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-05.jpg" alt="">
                                                <ul>
                                                    <li>Thomas Herzberg</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Interior Design</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$900</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>0660 616 82 76</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Kalispell, Montana</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Interior</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-20.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-02.jpg" alt="">
                                                <ul>
                                                    <li>Laura Stewart</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Electronics Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$300</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>(07) 4516 0420</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Electra, Texas</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Electronics</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-22.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-03.jpg" alt="">
                                                <ul>
                                                    <li>Todd Easter</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Electrical Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$600</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>28-62-76-32</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Wayne, New Jersey</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Electrical</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-21.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-05.jpg" alt="">
                                                <ul>
                                                    <li>Daniel Odom</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Cleaning Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$700</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>442 82 780</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Sylvester, Georgia</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Cleaning</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-22.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-04.jpg" alt="">
                                                <ul>
                                                    <li>David Worthey</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Computer  Service</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$400</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>514-756-6436</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Los Angeles, California</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Computer</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-20.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-02.jpg" alt="">
                                                <ul>
                                                    <li>Thomas Herzberg</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Interior Designing</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$300</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>030 44 89 44</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Hanover, Maryland</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Interior</p>
                                    </div>
                                </div>
                                <div class="service-widget">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../../../assets/img/services/service-22.jpg">
                                        </router-link>
                                        <div class="item-info">
                                            <div class="service-rate">
                                                <img src="../../../../assets/img/customer/user-05.jpg" alt="">
                                                <ul>
                                                    <li>Mary Hack</li>
                                                    <li class="rating mapgridrating1">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star "></i>
                                                        <span class="d-inline-block average-rating">(5)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Building Construction</router-link>
                                        </h3>
                                        <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                        <h4>$500</h4>
                                        <div class="user-info">
                                            <div class="row">	
                                                <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
                                                    <span>792 5457</span>
                                                </span>
                                                <span class="col ser-location">
                                                    <i class="fas fa-map-marker-alt ms-1"></i><span>Wayne, New Jersey</span> 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-footer-text">
                                        <p>Construction</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sell-view-btn text-center" >
                    <router-link to="/search" class="btn btn-view ">View All </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
	export default {
	mounted() {
        if($('.service-slider').length > 0) {
		$('.service-slider').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>