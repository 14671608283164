<template>
<!-- Sidebar -->
<div class="sidebar" id="sidebar">
	<div class="sidebar-logo">
		<router-link to="/admin/index">
			<img src="../../assets/img/logo-icon.png" class="img-fluid" alt="">
		</router-link>
	</div>
	<div class="sidebar-inner">
		<perfect-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle"> 
		<div id="sidebar-menu" class="sidebar-menu">
			<ul>
				<li>
					<router-link to="/admin/index"><i class="fas fa-columns"></i> <span>Dashboard</span></router-link>
				</li>
				<li>
					<router-link to="/admin/categories" :class="{'active': currentPath === '/admin/add-category' || currentPath === '/admin/edit-category'}"><i class="fas fa-layer-group"></i> <span>Categories</span></router-link>
				</li>
				<li>
					<router-link to="/admin/subcategories" :class="{'active': currentPath === '/admin/add-subcategory' || currentPath === '/admin/edit-subcategory'}"><i class="fab fa-buffer"></i> <span>Sub Categories</span></router-link>
				</li>
				<li>
					<router-link to="/admin/service-list" :class="{'active': currentPath === '/admin/service-details'}"><i class="fas fa-bullhorn"></i> <span> Services</span></router-link>
				</li>
				<li>
					<router-link to="/admin/total-report" :class="{'active': currentPath === '/admin/inprogress-report' || currentPath === '/admin/pending-report' || currentPath === '/admin/reject-report' || currentPath === '/admin/cancel-report' || currentPath === '/admin/complete-report'}"><i class="far fa-calendar-check"></i> <span> Booking List</span></router-link>
				</li>
				<li>
					<router-link to="/admin/payment_list"><i class="fas fa-hashtag"></i> <span>Payments</span></router-link>
				</li>
				<li>
					<router-link to="/admin/ratingstype" :class="{'active': currentPath === '/admin/add-ratingstype' || currentPath === '/admin/edit-ratingstype'}"><i class="fas fa-star-half-alt"></i> <span>Rating Type</span></router-link>
				</li>
				<li>
					<router-link to="/admin/review-reports"><i class="fas fa-star"></i> <span>Ratings</span></router-link>
				</li>
				<li>
					<router-link to="/admin/subscriptions" :class="{'active': currentPath === '/admin/add-subscription' || currentPath === '/admin/edit-subscription'}"><i class="far fa-calendar-alt"></i> <span>Subscriptions</span></router-link>
				</li>
				<li>
					<router-link to="/admin/wallet" :class="{'active': currentPath === '/admin/wallet-history'}"><i class="fas fa-wallet"></i> <span> Wallet</span></router-link>
				</li>
				<li>
					<router-link to="/admin/service-providers"><i class="fas fa-user-tie"></i> <span> Service Providers</span></router-link>
				</li>
				<li>
					<router-link to="/admin/users"><i class="fas fa-user"></i> <span>Users</span></router-link>
				</li>
				<li class="submenu">
					<a href="javascript:void(0);"><i class="fas fa-clipboard"></i> <span> Invoices</span>
						<span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
					</a>
					<ul>
						<li><router-link to="/admin/invoices" :class="{'active': currentPath === '/admin/invoices-cancelled' || currentPath === '/admin/invoices-draft' || currentPath === '/admin/invoices-overdue' || currentPath === '/admin/invoices-recurring' || currentPath === '/admin/invoices-paid'}">Invoices List</router-link></li>
						<li><router-link to="/admin/invoice-grid">Invoices Grid</router-link></li>
						<li><router-link to="/admin/add-invoice">Add Invoices</router-link></li>
						<li><router-link to="/admin/edit-invoice">Edit Invoices</router-link></li>
						<li><router-link to="/admin/view-invoice">Invoices Details</router-link></li>
						<li><router-link to="/admin/invoices-settings" :class="{'active': currentPath === '/admin/tax-settings' || currentPath === '/admin/bank-settings'}">Invoices Settings</router-link></li>
					</ul>
				</li>
				<li> 
					<router-link to="/admin/settings" :class="{'active': currentPath === '/admin/localization-details' || currentPath === '/admin/others-settings' || currentPath === '/admin/payment-settings' || currentPath === '/admin/seo-settings' || currentPath === '/admin/email-settings' || currentPath === '/admin/social-settings' || currentPath === '/admin/social-links'}"><i class="fas fa-cog"></i> <span>Settings</span></router-link>
				</li>
				<li class="submenu">
					<a href="javascript:void(0);"><i class="fas fa-cog"></i> <span> Frontend Settings</span>
						<span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
					</a>
					<ul>
						<li>
							<router-link to="/admin/front-settings"> <span> Header Settings</span></router-link>
						</li> 
						<li>
							<router-link to="/admin/footer-settings"> <span>Footer Settings</span></router-link>
						</li>
						<li>
							<router-link to="/admin/pages" :class="{'active': currentPath === '/admin/home-page' || currentPath === '/admin/pages' || currentPath === '/admin/add-faq' || currentPath === '/admin/privacy-policy'}"> <span>Pages </span></router-link>
						</li>	
					</ul>
				</li>
			</ul>
		</div>
	</perfect-scrollbar>
	</div>
</div>
<!-- /Sidebar -->
</template>
<script>
import {PerfectScrollbar}  from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
export default {
	computed: {
        currentPath() {
            return this.$route.name;
        }

    },
components: {
PerfectScrollbar ,
},
mounted() {

  },
data() {
	return {
	  settings: {
suppressScrollX: true,
},
	  activeClass: 'active',
	};
			//  isactive : true
  },
  methods: {
  scrollHanle(evt) {
	console.log(evt)
  }
},
}
</script>
<style>
  .scroll-area {
	position: relative;
	margin: auto;
	height: calc(100vh - 60px);
	background-color: transparent !important;
}
.sidebar-menu ul li a.active {
	background-color: #ff0080;
    color: #fff;
}
</style>