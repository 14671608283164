<template>
<tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> All Days
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- monday -->
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Monday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        
                                                        <!-- tuesday -->
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Tuesday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- wednesday -->
                                                        
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" class="me-1"> Wednesday
                                                            </td>
                                                            <td class="w-180">
                                                                From time 
                                                                <span class="time-select mb-1">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td class="w-155">
                                                                To time
                                                                <span class="time-select">
                                                                    <select class="form-control form-select">
                                                                        <option>Select Time</option>
                                                                        <option>00:00 AM</option>
                                                                        <option>01:00 AM</option>
                                                                        <option>02:00 AM</option>
                                                                        <option>03:00 AM</option>
                                                                        <option>04:00 AM</option>
                                                                        <option>05:00 AM</option>
                                                                        <option>06:00 AM</option>
                                                                        <option>07:00 AM</option>
                                                                        <option>08:00 AM</option>
                                                                        <option>09:00 AM</option>
                                                                        <option>10:00 AM</option>
                                                                        <option>11:00 AM</option>
                                                                        <option>12:00 PM</option>
                                                                        <option>01:00 PM</option>
                                                                        <option>02:00 PM</option>
                                                                        <option>03:00 PM</option>
                                                                        <option>04:00 PM</option>
                                                                        <option>05:00 PM</option>
                                                                        <option>06:00 PM</option>
                                                                        <option>07:00 PM</option>
                                                                        <option>08:00 PM</option>
                                                                        <option>09:00 PM</option>
                                                                        <option>10:00 PM</option>
                                                                        <option>11:00 PM</option>
                                                                    </select>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <!-- thursday -->
</template>