<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">All Notifications</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <div class="admin-noti-wrapper">
                        <div class="noti-list">
                            <div class="noti-avatar">
                                <img src="https://truelysell.com/assets/img/user.jpg" alt="">
                            </div>
                            <div class="noti-contents">
                                <h3>uiuii  have been subscribed</h3>
                                <span>31-08-2020 05:26 AM</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>