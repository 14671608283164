<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-lg-8 m-auto">
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="page-title">Privacy Policy</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="row">
                                <div class=" col-lg-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <div class="d-flex align-items-center w-100">
                                                    <div class="col">
                                                        <h5>Visibility</h5>
                                                        <p class="mb-0">Status of your page visibility on website.</p>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="status-toggle">
                                                            <input id="visibility" class="check" type="checkbox" checked="">
                                                            <label for="visibility" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" value="Privacy Policy">
                                            </div>
                                            <div class="form-group">
                                                <label>Slug:</label>
                                                <input type="text" class="form-control" value="privacy-policy">
                                            </div>
                                            <div class="form-group">
                                                <label>Content:</label>
                                                <textarea class="form-control"></textarea>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      components: {
     
      },
      mounted() {
      },
    }
  </Script>