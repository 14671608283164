<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-lg-8 m-auto">
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="page-title">Frontend Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                            
                            <div class="row">
                                <div class=" col-lg-12 col-sm-12 col-12">
                                    <frontcontent></frontcontent>
                                    <frontcontent1></frontcontent1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      components: {
     
      },
      mounted() {
      },
    }
  </Script>