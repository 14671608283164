<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Pages</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table custom-table mb-0 datatable" id="pages">
                                            <thead>
                                                <tr>
                                                    <th>Page </th>
                                                    <th>Slug</th>
                                                    <th >Status</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in pages" :key="item.id">
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.name1}}</td>
                                                    <td class="text-center">
                                                        <div class="status-toggle">
                                                            <input id="home" class="check" type="checkbox" checked>
                                                            <label for="home" class="checktoggle">{{item.button}}</label>
                                                        </div>
                                                    </td>
                                                    <td class="text-end">
                                                        <router-link to="/admin/home-page" class="btn btn-sm bg-success-light me-2">
                                                            <i class="far fa-edit me-1"></i>Edit
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
	import pages from '../../../assets/json/admin/pages.json'
    import util from '../../../assets/utils/util'
	export default {
    mounted() {
    util.datatable('#pages')  
    },
	data() {
        return {
            pages: pages
        }
    }
	}
	</script>