<template>

	<section class="popular-services">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="row">
						<div class="col-md-6">
							<div class="heading aos" data-aos="fade-up">
								<h2>Most Popular Services</h2>
								  <span>Explore the greates our services. You won’t be disappointed</span>
							</div>
						</div>
						<div class="col-md-6">
							<div class="viewall aos" data-aos="fade-up">
								<h4><router-link to="/categories">View All <i class="fas fa-angle-right"></i></router-link></h4>
								<span>Most Popular</span>
							</div>
						</div>
					</div>

					<!-- <div class="service-carouselindex slider">
							<div class="service-slider aos" data-aos="fade-up" v-for="item in popular" :key="item.id">
								<div class="service-widget">
									<div class="service-img">
										<router-link to="/service-details">
											<img class="img-fluid serv-img" alt="Service Image" :src="loadImg(item.img)">
                                        </router-link>
										<div class="fav-btn">
											<a href="javascript:void(0)" class="fav-icon">
												<i class="fas fa-heart"></i>
											</a>
										</div>
										<div class="item-info">
											<div class="service-user">
												<a href="javascript:void(0);">
													<img :src="loadImg1(item.img1)" alt="">
												</a>	
												<span class="service-price">{{item.rate}}</span>
											</div>
											<div class="cate-list">
												<router-link class="bg-yellow" to="/service-details">{{item.name}}</router-link>
											</div>
										</div>
									</div>
									<div class="service-content">
										<h3 class="title">
											<router-link to="/service-details">{{item.description}}</router-link>
										</h3>
										<div class="rating mapgridrating1">	
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star"></i>		
											<span class="d-inline-block average-rating">{{item.ratings}}</span>
										</div>
										<div class="user-info">
											<div class="row">	
												<span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
													<span>{{item.number}}</span>
												</span>
												<span class="col ser-location">
													<span>{{item.name1}}</span> <i class="fas fa-map-marker-alt ms-1"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> -->
					
					<div class="service-carouselindex slider" v-if="apiLoaded">
						<div class="service-slider aos" data-aos="fade-up" v-for="item in users_subspecialization" :key="item.id">
							<div class="service-widget">
								<div class="service-img">
									<router-link :to='"/service-details/"+item.specialization_id+"/"+item.id'>
										<img class="img-fluid serv-img" alt="Service Image" :src="$store.state.apiImage+item.image">
									</router-link>
									<!-- <div class="fav-btn">
										<a href="javascript:void(0)" class="fav-icon">
											<i class="fas fa-heart"></i>
										</a>
									</div> -->
									<div class="item-info">
										<div class="service-user">
											<a href="javascript:void(0);">
												<img :src="$store.state.apiImage+item.specializationinfo.image" alt="">
											</a>	
											<!-- <span class="service-price">{{item.specializationinfo.specialization}}</span> -->
										</div>
										<div class="cate-list">
											<router-link class="bg-yellow" :to='"/service-details/"+item.specialization_id+"/"+item.id'>{{item.specializationinfo.specialization}}</router-link>
										</div>
									</div>
								</div>
								<div class="service-content">
									<h3 class="title">
										<router-link :to='"/service-details/"+item.specialization_id+"/"+item.id'>{{item.sub_specialization}}</router-link>
									</h3>
									<!-- <div class="rating mapgridrating1">	
										<i class="fas fa-star filled"></i>
										<i class="fas fa-star filled"></i>
										<i class="fas fa-star filled"></i>
										<i class="fas fa-star filled"></i>
										<i class="fas fa-star"></i>		
										<span class="d-inline-block average-rating">{{item.sub_specialization}}</span>
									</div>
									<div class="user-info">
										<div class="row">	
											<span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> 
												<span>{{item.sub_specialization}}</span>
											</span>
											<span class="col ser-location">
												<span>{{item.sub_specialization}}</span> <i class="fas fa-map-marker-alt ms-1"></i>
											</span>
										</div>
									</div> -->
								</div>
							</div>
						</div>
					</div> 
				</div>
			</div>
		</div>
	</section>
	
</template>
<script>
import axios from 'axios';
import popular from '../../../../assets/json/website/home/popular.json'
const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
const images1 = require.context('../../../../assets/img/customer', false, /\.png$|\.jpg$/)
export default {
mounted() {
if($('.service-carouselindex').length > 0) {
$('.service-carouselindex').slick({
	dots: true,
	autoplay:false,
	infinite: true,
	prevArrow: false,
	nextArrow: false,
	slidesToShow: 3,
	  slidesToScroll: 3,
	  responsive: [
		{
		  breakpoint: 991,
		  settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
		  }
		},
		{
		  breakpoint: 767,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		  }
		},
	]
});
};
this.getsubSpecializationMasterList();
},
	methods:{
	loadImg(imgPath) {
		return images('./' + imgPath).default
	},
	loadImg1(img1Path) {
		return images1('./' + img1Path).default
	},
	getsubSpecializationMasterList() {
	axios
		.get(this.$store.state.apiEndpoint + "api/getSubSpecializationlist")
		.then(response => {
		this.users_subspecialization = response.data;
		this.apiLoaded = true;
		setTimeout(() => {
			if($('.service-carouselindex').length > 0) {
$('.service-carouselindex').slick({
	dots: true,
	autoplay:false,
	infinite: true,
	prevArrow: false,
	nextArrow: false,
	slidesToShow: 3,
	  slidesToScroll: 3,
	  responsive: [
		{
		  breakpoint: 991,
		  settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
		  }
		},
		{
		  breakpoint: 767,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		  }
		},
	]
});
};
		}, 200);
		console.log(response);
	})
		.catch(error => {
		console.log(error);
		alert("auth error");
	});
},
},
data() {
	return {
		users_subspecialization: [],
		popular: popular,
		apiLoaded:false,
	}
}
}
</script>