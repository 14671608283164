<template>
    <section class="hero-section hero-three">
        <div class="layer">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-lg-8">
                        <div class="section-search-box d-flex align-items-center aos" data-aos="fade-up">
                            <div class="section-search">
                                <h3><span>World's Largest</span> <br> Marketplace</h3>
                                <p>Search From 150 Awesome Verified Ads!</p>
                                <div class="search-box">
                                    <form> 
                                        <div class="search-input line">
                                            <i class="fa fa-th-large bficon"></i>
                                            <div class="form-group mb-0">
                                                <input type="text" class="form-control" placeholder="What are you looking for?">
                                            </div>
                                        </div>
                                        <div class="search-input location-light">
                                            <i class="fa-light fa-location-dot"></i>
                                            <div class="form-group mb-0">
                                                <input type="text" class="form-control" placeholder="Your Location"> 
                                            </div>
                                        </div>
                                        <router-link to="/search">
                                        <div class="search-btn">
                                            <button class="btn search_service" ><i class="fa fa-search"></i>Search</button>
                                        </div>
                                        </router-link>
                                    </form>
                                </div>
                                <div class="popular-product-list">
                                    <p><span>Most Popular :</span> <router-link to="/search">Electrical Works</router-link>,<router-link to="/search">Cleaning</router-link>,<router-link to="/search">AC Repair</router-link>,<router-link to="/search">Computer</router-link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>