<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title">Settings</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <settingsidebar></settingsidebar>
                    
                    <div class="row">
                        <div class="col-lg-6 col-md-8">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Social Link Settings</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <form>

                                        <div class="settings-form">
                                            
                                            <sociallinkcontent></sociallinkcontent>

                                            <sociallinkcontent1></sociallinkcontent1>

                                        </div>
                                        <div class="form-group">
                                            <a href="javascript:void(0);" class="btn add-links">
                                                <i class="fas fa-plus me-1"></i> Add More
                                            </a>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="settings-btns">
                                                <button type="submit" class="btn btn-orange">Submit</button>
                                                <button type="submit" class="btn btn-grey">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      mounted() {
      $(".settings-form").on('click','.trash', function () {
	  $(this).closest('.links-cont').remove();
	  return false;
      });
      $(document).on("click",".add-links",function () {
	  var experiencecontent = '<div class="row form-row links-cont">' +
	  '<div class="form-group d-flex">' +
	  '<button class="btn social-icon"><i class="feather-github"></i></button>' +
	  '<input type="text" class="form-control" placeholder="Social Link">' +
	  '<div><a href="javascript:void(0);" class="btn trash"><i class="feather-trash-2"></i></a></div>' +
	  '</div>' +
	  '</div>';
	  $(".settings-form").append(experiencecontent);
	  return false;
      });
      },
    }
  </Script>