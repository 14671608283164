<template>
<div class="col-lg-3 theiaStickySidebar">
    <div class="stickyside">
						<div class="card filter-card">
							<div class="card-body">
								<h4 class="card-title mb-4">Search Filter</h4>
								<form id="search_form">
									<div class="filter-widget">
										<div class="filter-list">
											<!-- <h4 class="filter-title">Keyword</h4> -->
											<!-- <input type="text" class="form-control" placeholder="What are you looking for?"> -->
										</div>
										<!-- <div class="filter-list">
											<h4 class="filter-title">Sort By</h4>
											<select class="form-control selectbox select form-select">
												<option>Sort By</option>
												<option>Price Low to High</option>
												<option>Price High to Low</option>
												<option>Newest</option>
											</select>
										</div> -->
										<div class="filter-list">
											<h4 class="filter-title">Categories</h4>
											<select class="form-control form-control selectbox select form-select" v-model="catid">
												<option v-for="item in users_specialization" v-bind:key="item.id" v-bind:value="item.id">{{item.specialization}}</option>
												<!-- <option>Computer</option>
												<option selected="">Automobile</option>
												<option>Car Wash</option>
												<option>Cleaning</option>
												<option>Electrical</option>
												<option>Construction</option> -->
											</select>
										</div>
										<!-- <div class="filter-list">
											<h4 class="filter-title">Location</h4>
											<input class="form-control" type="text" placeholder="Search Location">
										</div> -->
									</div>
									<button class="btn btn-primary pl-5 pr-5 btn-block get_services w-100" @click="openSubCat(catid)" >Search</button>
								</form>
							</div>
						</div>
					</div>
                </div>
</template>
<script>
import axios from "axios";
export default {
		data() {
        return {
			// subspecializationinfoByid:[],
			users_specialization: [],
			catid:1
        }
    },
	methods:{
		openSubCat(catid){
// 			this.$router.push({
//     name: 'SubcatsPg', 
//     params: { catid: selectedValue }
// });
			this.$router.push("/Subcats/"+catid); 
			// this.$router.push("/Subcats/"+item.id); 
			// window.location.href = "/Subcats/"+item.id;
			// setTimeout(() => {
			// this.$router.go("/Subcats/"+item.id);
			// }, 200);
			
		},
		getSpecializationMasterList() {
		axios
			.get(this.$store.state.apiEndpoint + "api/SpecializationMasterList")
			.then(response => {
			this.users_specialization = response.data;
			console.log(response);
		})
			.catch(error => {
			console.log(error);
			alert("auth error");
		});
	},
	
    },
	mounted() {
		this.getSpecializationMasterList();
	},
}
</script>