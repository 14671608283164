<template>

	<div class="main-wrapper">

    <layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="section-header text-center">
                        <h2>Thanks for Booking </h2>
                    </div>  
                    <div class="submit-section">
                    <router-link to="/" class="btn btn-primary"> 
                    <button class="btn btn-primary" >Back to Home</button>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>