<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>
    
    <div class="content">
			<div class="container">
				<div class="row">
					<providersidebar></providersidebar>
					<div class="col-xl-9 col-md-8">
						<form>
							<div class="widget">
								<h4 class="widget-title">Profile Settings</h4> 
								<div class="row">
									<div class="col-xl-12">
										<h5 class="form-title">Basic Information</h5>
									</div>
									<div class="form-group col-xl-12">
										<div class="media align-items-center mb-3 d-flex">
											<img class="user-image" src="../../../../assets/img/provider/provider-01.jpg" alt="">
											<div class="media-body">
												<h5 class="mb-0">Thomas Herzberg</h5>
												<p>Max file size is 20mb</p>
												<div class="jstinput">
													<a href="javascript:void(0);" class="browsephoto">Browse</a>
												</div> 
											</div>
										</div>
									</div>
								</div>
							</div>
							<settingform></settingform>
						</form>
					</div>
				</div>
			</div>
		</div>
	
	<layout-footer></layout-footer>

	</div>

</template>