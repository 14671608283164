<template>
	<div>
		<div class="main-wrapper">
			<layout-headersetting></layout-headersetting>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title">Settings</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <settingsidebar></settingsidebar>
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">PHP Mail</h5>
                                    <div class="status-toggle d-flex justify-content-between align-items-center">
                                        <input type="checkbox" id="status_1" class="check">
                                        <label for="status_1" class="checktoggle">checkbox</label>
                                    </div>
                                </div>
                                <div class="card-body pt-0">
                                    <form>
                                        <div class="settings-form">
                                            <div class="form-group form-placeholder">
                                                <label>Email From Address <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Email Password <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Emails From Name <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="settings-btns">
                                                    <button type="submit" class="btn btn-orange">Submit</button>
                                                    <button type="submit" class="btn btn-grey">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">SMTP</h5>
                                    <div class="status-toggle d-flex justify-content-between align-items-center">
                                        <input type="checkbox" id="status_2" class="check" checked="">
                                        <label for="status_2" class="checktoggle">checkbox</label>
                                    </div>
                                </div>
                                <div class="card-body pt-0">
                                    <form>
                                        <div class="settings-form">
                                            <div class="form-group form-placeholder">
                                                <label>Email From Address <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Email Password <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Email Host <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group form-placeholder">
                                                <label>Email Port <span class="star-red">*</span></label>
                                                <input type="text" class="form-control">
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="settings-btns">
                                                    <button type="submit" class="btn btn-orange">Submit</button>
                                                    <button type="submit" class="btn btn-grey">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
    import Vue from 'vue'
    export default {
      data() {
      return {
        password: ["Select Mail Encryption", "Mail Encryption", "Encryption"]
      }
      },
      components: {
     
      },
      mounted() {
      },
    }
  </Script>